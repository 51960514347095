import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function WeekByWeekEdit(props) {
	const [week_number, setWeekNumber] = useState('');
	const sendTo = () => {
		if (0 < week_number < 42) {
			props.history.push(`/weekData/${week_number}`);
		}
	};
	return (
		<div>
			<Grid
				container
				item
				direction='row'
				justify='center'
				alignItems='center'>
				<span>Which Week Data you want to edit :</span>
				<TextField
					id='standard-number'
					label='Week'
					type='number'
					name='week_number'
					value={week_number}
					onChange={e => {
						setWeekNumber(e.target.value);
					}}
					InputLabelProps={{
						shrink: true
					}}
				/>
				<Button
					variant='contained'
					color='primary'
					type='submit'
					onClick={sendTo}>
					Submit
				</Button>
			</Grid>
		</div>
	);
}