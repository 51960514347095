import React, { Fragment } from 'react';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';

export default function TeamMember({ member }) {
	return (
		<StyleWrap>
			<Grid
				className='member-item'
				key={member.memberName}
				style={{
					backgroundImage: `url(${member.photo.jpeg})`,
					backgroundSize: 'contain'
				}}>
				<div className='mi-social'>
					<div className='mi-social-inner bg-gradient'>
						<a href='#'>
							<Icon className='fab fa-facebook' />
						</a>
						<a href='#'>
							<Icon className='fab fa-instagram' />
						</a>
						<a href='#'>
							<Icon className='fab fa-twitter' />
						</a>
						<a href='#'>
							<Icon className='fab fa-linkedin' />
						</a>
					</div>
				</div>
				<div className='mi-text'>
					<h5>{member.memberName}</h5>
					<span>{member.designation}</span>
				</div>
			</Grid>
		</StyleWrap>
	);
}

const StyleWrap = styled.div`
	.member-item {
		min-width: 300px;
		height: 420px;
		position: relative;
		overflow: hidden;
	}

	.member-item:hover .mi-social {
		top: 0;
	}

	.member-item:hover .mi-text {
		left: 20px;
	}

	.member-item .mi-social {
		text-align: center;
		position: relative;
		top: -60px;
		-webkit-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}

	.member-item .mi-social .mi-social-inner {
		display: inline-block;
		padding: 10px 23px 8px;
		border-radius: 0 0 4px 4px;
	}

	.member-item .mi-social .mi-social-inner a {
		display: inline-block;
		font-size: 18px;
		color: #ffffff;
		margin-right: 10px;
	}

	.member-item .mi-social .mi-social-inner a:last-child {
		margin-right: 0;
	}

	.member-item .mi-text {
		background: #ffffff;
		padding: 16px 0 18px 25px;
		position: absolute;
		left: -500px;
		bottom: 20px;
		width: calc(100% - 40px);
		-webkit-transition: all 0.5s ease-out;
		-o-transition: all 0.5s ease-out;
		transition: all 0.5s ease-out;
	}

	.member-item .mi-text h5 {
		font-size: 20px;
		color: #171822;
		font-weight: 600;
		margin-bottom: 5px;
	}

	.member-item .mi-text span {
		font-size: 16px;
		color: #f44949;
		display: block;
	}
`;