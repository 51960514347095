/* reducers should accept action and state and return the new state after executing the action */

import {
	FETCH_ALL_POSTS,
	FETCH_ARTICLE,
	FETCH_AUTHOR_POSTS,
	FETCH_CAT,
	ADD_ASIN,
	REMOVE_ASIN,
	ADD_CAT,
	CLEAR_POSTS,
	FETCH_WEEK_DATA,
	MAKE_DRAFT,
	DELETE_ARTICLE,
	RESET_WEEK_DATA,
	FETCH_ALL_MEDICAL
} from '../actions/types';

const initialState = {
	posts: [],
	article: [],
	author_posts: [],
	cats: [],
	isloaded: false,
	weekData: [],
	didWeekDataLoad: false,
	id: '',
	medicalReviewers: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_ALL_POSTS:
			return {
				...state,
				posts: action.payload,
				isloaded: true,
			};
		case FETCH_ALL_MEDICAL:
			return {
				...state,
				medicalReviewers: action.payload,
			};
		case DELETE_ARTICLE:
			return {
				...state,
				posts: action.payload,
				isloaded: true,
			};
		case CLEAR_POSTS:
			return {
				...state,
				posts: [],
				isloaded: false,
			};
		case ADD_ASIN:
			return {
				...state,
				posts: action.payload,
				isloaded: true,
			};
		case REMOVE_ASIN:
			return {
				...state,
				posts: action.payload,
				isloaded: true,
			};
		case FETCH_ARTICLE:
			return {
				...state,
				article: action.payload,
				id: action.payload[0]._id
			};
		case FETCH_CAT:
			return {
				...state,
				cats: action.payload,
			};
		case ADD_CAT:
			return {
				...state,
				cats: action.payload,
			};
		case FETCH_AUTHOR_POSTS:
			return {
				...state,
				author_posts: action.payload,
			};
		case FETCH_WEEK_DATA:
			return {
				...state,
				weekData: action.payload,
				didWeekDataLoad: true,
			};
		case RESET_WEEK_DATA:
			return {
				...state,
				weekData: [],
				didWeekDataLoad: false,
			};
		default:
			return state;
	}
}