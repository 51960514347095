import {
	FETCH_USER_LIST,
	SET_ADMIN_USER,
	FETCH_MEMBERS_LIST,
	DELETE_MEMBER
} from '../actions/types';

const initialState = {
	usersList: [],
	user: '',
	isAdmin: false,
	isReviewer: false,
	members: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_USER_LIST:
			return {
				...state,
				usersList: action.payload
			};
		case FETCH_MEMBERS_LIST:
			return {
				...state,
				members: action.payload
			};
		case DELETE_MEMBER:
			return {
				...state,
				members: action.payload
			};
		case SET_ADMIN_USER:
			localStorage.setItem("user", JSON.stringify(action.payload))
			if (action.payload.admin) {
				localStorage.setItem("privilege", 'admin')
				return {
					...state,
					user: action.payload,
					isAdmin: true
				};
			} else if (action.payload.reviewer) {
				localStorage.setItem("privilege", 'reviewer')
				return {
					...state,
					user: action.payload,
					isReviewer: true
				};
			} else {
				return {
					...state,
					user: action.payload
				};
			}

		default:
			return state;
	}
}