import React, { Component } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import Reset from '@mui/icons-material/RotateLeft';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import { Grid } from '@mui/material';
import axios from 'axios';
// var request = require('request-promise');
const APP_ID = '5e398283';
const APP_KEY = 'c61039b289c24ff82e754f68e9496339';

export default class IngredientAdd extends Component {
	state = {
		query: '',
		displayName: '',
		formOfIng: '',
		results: null,
		ingredientName: null,
		ingredientNutrition: [],
		parsedData: [],
		options: {},
		optionsSet: false,
		nutritionalData: [],
		waiting: false,
		imageUrl: '',
		imageWebpUrl: ''
	};
	searchIngredient = async () => {
		this.startSpinner();
		try {
			const response = await fetch(
				`https://api.edamam.com/api/food-database/parser?nutrition-type=logging&ingr=${this.state.query}&app_id=${APP_ID}&app_key=${APP_KEY}`
			);
			const data = await response.json();
			this.setIngredient(data);
		} catch (err) {
			console.log(err);
		} finally {
			this.stopSpinner();
		}
	};
	onChangeImage = e => {
		// this.setState({ uploading: true })
		let uploadedFile = e.target.files[0];
		// const formData = new FormData()
		const formData = new FormData();
		formData.append('image', uploadedFile);
		axios
			.post('https://api.bestmom.org/upload/smallIcons', formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then(response => {
				console.log(response);
				this.setState({
					imageUrl: response.data.file.url,
					imageWebpUrl: response.data.file.webpUrl
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	startSpinner = () => {
		this.setState({
			waiting: true
		});
	};
	stopSpinner = () => {
		this.setState({
			waiting: false
		});
	};
	getNutrients = async () => {
		this.startSpinner();
		try {
			if (this.state.optionsSet) {
				const nutrients_URL = `https://api.edamam.com/api/food-database/nutrients?app_id=${APP_ID}&app_key=${APP_KEY}`;
				const food_items_request_body = JSON.stringify(
					this.state.options
				);
				var options = {
					body: food_items_request_body,
					url: nutrients_URL,
					headers: {
						'Content-Type': 'application/json'
					}
				};
				let data2 = {};
				function callback(error, response, body) {
					if (!error && response.statusCode == 200) {
						data2 = JSON.parse(body);
						console.log(data2);
						return data2;
					}
				}
				// eslint-disable-next-line no-undef
				await post(options, callback);

				this.setNutritionalData(data2);
			} else {
				toast.error('Further Nutritional Data not available', {
					containerId: 'A'
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			this.stopSpinner();
		}
	};
	setIngredient = data => {
		// console.log(data);
		let CA,
			CHOCDF,
			CHOLE,
			FAMS,
			FAPU,
			FASAT,
			FAT,
			FATRN,
			FE,
			FIBTG,
			FOLDFE,
			K,
			MG,
			NA,
			ENERC_KCAL,
			NIA,
			P,
			PROCNT,
			RIBF,
			SUGAR,
			THIA,
			TOCPHA,
			VITA_RAE,
			VITB12,
			VITB6A,
			VITC,
			VITD,
			VITK1;
		if (data.parsed.length > 0) {
			({
				CA,
				CHOCDF,
				CHOLE,
				FAMS,
				FAPU,
				FASAT,
				FAT,
				FATRN,
				FE,
				FIBTG,
				FOLDFE,
				K,
				MG,
				NA,
				ENERC_KCAL,
				NIA,
				P,
				PROCNT,
				RIBF,
				SUGAR,
				THIA,
				TOCPHA,
				VITA_RAE,
				VITB12,
				VITB6A,
				VITC,
				VITD,
				VITK1
			} = data.parsed[0].food.nutrients);
			this.setState({
				ingredientName: data.parsed[0].food.label,
				ingredientNutrition: [
					{ CA: '	Calcium	', value: CA, uom: '	mg	' },
					{ CHOCDF: '	Carbs	', value: CHOCDF, uom: '	g	' },
					{ CHOLE: '	Cholesterol	', value: CHOLE, uom: '	mg	' },
					{ FAMS: '	Monounsaturated	', value: FAMS, uom: '	g	' },
					{ FAPU: '	Polyunsaturated	', value: FAPU, uom: '	g	' },
					{ FASAT: '	Saturated	', value: FASAT, uom: '	g	' },
					{ FAT: '	Fat	', value: FAT, uom: '	g	' },
					{ FATRN: '	Trans	', value: FATRN, uom: '	g	' },
					{ FE: '	Iron	', value: FE, uom: '	mg	' },
					{ FIBTG: '	Fiber	', value: FIBTG, uom: '	g	' },
					{ FOLDFE: '	Folate (Equivalent)	', value: FOLDFE, uom: '	æg	' },
					{ K: '	Potassium	', value: K, uom: '	mg	' },
					{ MG: '	Magnesium	', value: MG, uom: '	mg	' },
					{ NA: '	Sodium	', value: NA, uom: '	mg	' },
					{ ENERC_KCAL: '	Energy	', value: ENERC_KCAL, uom: '	kcal	' },
					{ NIA: '	Niacin (B3)	', value: NIA, uom: '	mg	' },
					{ P: '	Phosphorus	', value: P, uom: '	mg	' },
					{ PROCNT: '	Protein	', value: PROCNT, uom: '	g	' },
					{ RIBF: '	Riboflavin (B2)	', value: RIBF, uom: '	mg	' },
					{ SUGAR: '	Sugars	', value: SUGAR, uom: '	g	' },
					{ THIA: '	Thiamin (B1)	', value: THIA, uom: '	mg	' },
					{ TOCPHA: '	Vitamin E	', value: TOCPHA, uom: '	mg	' },
					{ VITA_RAE: '	Vitamin A	', value: VITA_RAE, uom: '	æg	' },
					{ VITB12: '	Vitamin B12	', value: VITB12, uom: '	æg	' },
					{ VITB6A: '	Vitamin B6	', value: VITB6A, uom: '	mg	' },
					{ VITC: '	Vitamin C	', value: VITC, uom: '	mg	' },
					{ VITD: '	Vitamin D	', value: VITD, uom: '	æg	' },
					{ VITK1: '	Vitamin K	', value: VITK1, uom: '	æg	' }
				],
				parsedData: data.parsed
			});
			if (data.parsed[0].quantity && data.parsed[0].measure) {
				this.setState({
					options: {
						ingredients: [
							{
								quantity: data.parsed[0].quantity,
								measureURI: data.parsed[0].measure.uri,
								foodId: data.parsed[0].food.foodId
							}
						]
					},
					optionsSet: true
				});
			}
		}
	};
	setNutritionalData = data => {
		console.log('setting nutritional data');
		console.log(data.totalNutrients);

		let assignArray = [
			{ label: 'Calcium', quantity: 0, unit: 'mg' },
			{ label: 'Carbs', quantity: 0, unit: 'g' },
			{ label: 'Cholesterol', quantity: 0, unit: 'mg' },
			{ label: 'Monounsaturated', quantity: 0, unit: 'g' },
			{ label: 'Polyunsaturated', quantity: 0, unit: 'g' },
			{ label: 'Saturated', quantity: 0, unit: 'g' },
			{ label: 'Fat', quantity: 0, unit: 'g' },
			{ label: 'Trans', quantity: 0, unit: 'g' },
			{ label: 'Iron', quantity: 0, unit: 'mg' },
			{ label: 'Fiber', quantity: 0, unit: 'g' },
			{ label: 'Folate (Equivalent)', quantity: 0, unit: 'æg' },
			{ label: 'Potassium', quantity: 0, unit: 'mg' },
			{ label: 'Magnesium', quantity: 0, unit: 'mg' },
			{ label: 'Sodium', quantity: 0, unit: 'mg' },
			{ label: 'Energy', quantity: 0, unit: 'kcal' },
			{ label: 'Niacin (B3)', quantity: 0, unit: 'mg' },
			{ label: 'Phosphorus', quantity: 0, unit: 'mg' },
			{ label: 'Protein', quantity: 0, unit: 'g' },
			{ label: 'Riboflavin (B2)', quantity: 0, unit: 'mg' },
			{ label: 'Sugars', quantity: 0, unit: 'g' },
			{ label: 'Thiamin (B1)', quantity: 0, unit: 'mg' },
			{ label: 'Vitamin E', quantity: 0, unit: 'mg' },
			{ label: 'Vitamin A', quantity: 0, unit: 'æg' },
			{ label: 'Vitamin B12', quantity: 0, unit: 'æg' },
			{ label: 'Vitamin B6', quantity: 0, unit: 'mg' },
			{ label: 'Vitamin C', quantity: 0, unit: 'mg' },
			{ label: 'Vitamin D', quantity: 0, unit: 'æg' },
			{ label: 'Vitamin K', quantity: 0, unit: 'æg' }
		];

		let {
			CA,
			CHOCDF,
			CHOLE,
			FAMS,
			FAPU,
			FASAT,
			FAT,
			FATRN,
			FE,
			FIBTG,
			FOLDFE,
			K,
			MG,
			NA,
			ENERC_KCAL,
			NIA,
			P,
			PROCNT,
			RIBF,
			SUGAR,
			THIA,
			TOCPHA,
			VITA_RAE,
			VITB12,
			VITB6A,
			VITC,
			VITD,
			VITK1
		} = data.totalNutrients;
		let checkArray = [
			CA,
			CHOCDF,
			CHOLE,
			FAMS,
			FAPU,
			FASAT,
			FAT,
			FATRN,
			FE,
			FIBTG,
			FOLDFE,
			K,
			MG,
			NA,
			ENERC_KCAL,
			NIA,
			P,
			PROCNT,
			RIBF,
			SUGAR,
			THIA,
			TOCPHA,
			VITA_RAE,
			VITB12,
			VITB6A,
			VITC,
			VITD,
			VITK1
		];

		checkArray.map((ele, i) => {
			if (ele === undefined) {
				checkArray[i] = assignArray[i];
			}
			return ele;
		});
		console.log(checkArray);

		let totalNutrients = {
			CA: checkArray[0],
			CHOCDF: checkArray[1],
			CHOLE: checkArray[2],
			FAMS: checkArray[3],
			FAPU: checkArray[4],
			FASAT: checkArray[5],
			FAT: checkArray[6],
			FATRN: checkArray[7],
			FE: checkArray[8],
			FIBTG: checkArray[9],
			FOLDFE: checkArray[10],
			K: checkArray[11],
			MG: checkArray[12],
			NA: checkArray[13],
			ENERC_KCAL: checkArray[14],
			NIA: checkArray[15],
			P: checkArray[16],
			PROCNT: checkArray[17],
			RIBF: checkArray[18],
			SUGAR: checkArray[19],
			THIA: checkArray[20],
			TOCPHA: checkArray[21],
			VITA_RAE: checkArray[22],
			VITB12: checkArray[23],
			VITB6A: checkArray[24],
			VITC: checkArray[25],
			VITD: checkArray[26],
			VITK1: checkArray[27]
		};
		console.log(totalNutrients);
		data.totalNutrients = totalNutrients;
		this.setState({
			nutritionalData: [data]
		});
	};
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	resetData = () => {
		this.setState({
			query: '',
			displayName: '',
			formOfIng: '',
			results: null,
			ingredientName: null,
			ingredientImageUrl: null,
			ingredientNutrition: [],
			parsedData: [],
			options: {},
			optionsSet: false,
			nutritionalData: [],
			waiting: false,
			imageUrl: '',
			imageWebpUrl: ''
		});
	};
	saveIngredient = () => {
		if (this.state.imageUrl) {
			if (this.state.displayName) {
				let dataToSend = {
					displayName: this.state.displayName,
					formOfIng: this.state.formOfIng,
					ingredientName: this.state.ingredientName,
					imageUrl: this.state.imageUrl,
					imageWebpUrl: this.state.imageWebpUrl,
					healthLabels: this.state.nutritionalData[0]['healthLabels'],
					caloriesPerWeight: this.state.nutritionalData[0][
						'calories'
					],
					cautions: this.state.nutritionalData[0]['cautions'],
					dietLabels: this.state.nutritionalData[0]['dietLabels'],
					totalDaily: this.state.nutritionalData[0]['totalDaily'],
					totalNutrients: this.state.nutritionalData[0][
						'totalNutrients'
					],
					servings: this.state.nutritionalData[0]['yield'],
					weight: this.state.nutritionalData[0]['totalWeight']
				};

				axios({
					method: 'post',
					url: `https://api.bestmom.org/article/addIngredient`,
					data: dataToSend
				})
					.then(response => {
						toast.dismiss();
						console.log(response.data);
						toast.success(
							`Success! saved with id : ${response.data}`,
							{
								position: toast.POSITION.TOP_CENTER
							}
						);
						setTimeout(() => {
							this.resetData();
						}, 2000);
					})
					.catch(function (error) {
						toast(
							`save unsuccessful please save the data externally and contact Admin! ${error}`,
							{ containerId: 'A' }
						);
					});
			} else {
				toast.error('Pls add display Name should be unique', {
					containerId: 'A'
				});
			}
		} else {
			toast.error('you forgot to add image', { containerId: 'A' });
		}
	};
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
		} else {
			this.props.history.push('/adminlogin');
		}
		}
	render() {
		return (
			<Wrapper waitingDisplay={this.state.waiting}>
				<Modal
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					open={this.state.waiting}>
					<div
						style={{
							background: 'transparent',
							width: '50%',
							margin: '25%',
							height: '100vh',
							paddingLeft: '25vw'
						}}>
						<CircularProgress color='secondary' />
					</div>
				</Modal>

				<div className='searchBar'>
					<div className='wrap'>
						<div className='search'>
							<input
								type='text'
								name='query'
								value={this.state.query}
								onChange={this.handleChange}
								className='searchTerm'
								placeholder='search for ingredients here'
							/>
							<button
								onClick={this.searchIngredient}
								type='submit'
								className='searchButton'>
								<SearchIcon />
							</button>
							<button
								onClick={this.resetData}
								type='submit'
								className='searchButton'>
								<Reset />
							</button>
						</div>
					</div>
				</div>
				<div className='remaining'>
					<Grid
						container
						direction='row'
						justify='space-between'
						alignItems='flex-start'>
						<Grid item>
							{this.state.ingredientNutrition.length > 0 && (
								<div>
									<div>
										{' '}
										Display Name :
										<input
											type='text'
											name='displayName'
											placeholder='display name'
											value={this.state.displayName}
											onChange={this.handleChange}
										/>
										<input
											type='text'
											name='formOfIng'
											placeholder='ingredient form eg grounded granulated'
											value={this.state.formOfIng}
											onChange={this.handleChange}
										/>
									</div>

									<button onClick={this.getNutrients}>
										GET FURTHER NUTRIENTS INFO
									</button>
									{this.state.ingredientName && (
										<h4>
											INGREDIENT NAME :{' '}
											{this.state.ingredientName}
										</h4>
									)}
									<p>Nutritional Value per 100g</p>
									<table
										class='table'
										style={{ maxWidth: '300px' }}>
										<thead>
											<tr>
												<th>S.no</th>
												<th>Nutrient Name</th>
												<th>Value</th>
											</tr>
										</thead>
										<tbody>
											{this.state.ingredientNutrition.map(
												(each, i) => {
													let obj = Object.entries(
														each
													);
													return (
														<tr key={i}>
															<td scope='row'>
																{i}
															</td>
															<td>{obj[0][1]}</td>
															<td>{obj[1][1]}</td>
														</tr>
													);
												}
											)}
										</tbody>
									</table>
								</div>
							)}
						</Grid>
						<Grid item>
							{this.state.nutritionalData.length > 0 && (
								<div>
									<Avatar
										style={{
											margin: '10px 10px',
											background: 'white',
											width: '50px',
											height: '50px'
										}}>
										{this.state.imageUrl ? (
											<img
												src={this.state.imageUrl}
												width={'100%'}
											/>
										) : (
												<img src='https://via.placeholder.com/50' />
											)}
									</Avatar>
									<div
										className='button'
										style={{ marginBottom: '20px' }}>
										<input
											type='file'
											id='image'
											onChange={this.onChangeImage}
										/>
									</div>
									<button onClick={this.saveIngredient}>
										SAVE INGREDIENT WITH DATA
									</button>
									<p>
										Nutritional Value per{' '}
										{
											this.state.nutritionalData[0]
												.totalWeight
										}
									</p>
									<p>
										serve Size :{' '}
										{this.state.nutritionalData[0].yield}
									</p>
									<p>
										Calories :{' '}
										{this.state.nutritionalData[0].calories}
									</p>
									<p>
										measureUri :{' '}
										{
											this.state.options.ingredients[0][
											'measureURI'
											]
										}
									</p>
									<table
										class='table'
										style={{ maxWidth: '300px' }}>
										<thead>
											<tr>
												<th>label</th>
												<th>qty</th>
												<th>unit</th>
											</tr>
										</thead>
										<tbody>
											{Object.entries(
												this.state.nutritionalData[0]
													.totalNutrients
											).map((eachNut, i) => {
												return (
													<tr key={i}>
														<td scope='row'>
															{eachNut[1].label}
														</td>
														<td>
															{(eachNut[1]
																.quantity *
																100) /
																this.state
																	.nutritionalData[0]
																	.totalWeight}
														</td>
														<td>
															{eachNut[1].unit}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							)}
						</Grid>
					</Grid>
				</div>
			</Wrapper>
		);
	}
}
const Wrapper = styled.div`
	.ingrendientIcon {
		width: 50px;
	}
	.searchBar {
		margin-top: 50px;
		height: 20px;
	}
	.remaining {
		margin-top: 110px;
	}
	.search {
		width: 60%;
		position: absolute;
		display: flex;
	}
	.searchTerm {
		width: 100%;
		border: 3px solid #00b4cc;
		border-right: none;
		padding: 5px;
		height: 40px;
		border-radius: 5px 0 0 5px;
		outline: none;
		color: #9dbfaf;
	}
	.searchTerm:focus {
		color: #00b4cc;
	}
	.searchButton {
		width: 40px;
		height: 40px;
		border: 1px solid #00b4cc;
		background: #00b4cc;
		text-align: center;
		color: #fff;
		border-radius: 0 5px 5px 0;
		cursor: pointer;
		font-size: 20px;
	}
`;