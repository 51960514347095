import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { fetchCats, addcat, addsubcat } from '../../redux/actions/PostActions';
import { toast } from 'react-toastify';

class CreateCat extends Component {
	state = {
		add_category: '',
		add_sub_category: '',
		cat_id: ''
	};
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
			this.props.fetchCats();
		} else {
			this.props.history.push('/adminlogin');
		}
	}
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	handleCategorySubmit = () => {
		if (this.state.add_category !== '') {
			let cat = this.state.add_category.toLowerCase();
			this.props.addcat(cat);
			toast.success('success');
			this.setState({ add_category: '' });
		} else {
			toast.warn('unsuccessful! - please enter the data');
		}
	};
	handleSubCatSubmit = () => {
		if (this.state.add_sub_category !== '') {
			if (this.state.cat_id !== '') {
				let subcat = this.state.add_sub_category.toLowerCase();
				this.props.addsubcat(subcat, this.state.cat_id);
				toast.success('success');
				this.setState({
					add_sub_category: '',
					cat_id: ''
				});
			} else {
				toast.warn(
					'please select the category to add this sub-category'
				);
			}
		} else {
			toast.warn('unsuccessful! - please enter the data');
		}
	};
	componentDidUpdate(prevProps, prevState) {
		if (this.props.posts.cats.length !== prevProps.posts.cats.length) {
		}
	}

	render() {
		let options;
		if (this.props.posts.cats !== undefined) {
			options = this.props.posts.cats.map(cat => {
				return (
					<option key={cat._id} value={cat._id}>
						{cat.category_name}
					</option>
				);
			});
			//console.log(options)

			//console.log(this.props)
			return (
				<>
					<h4>Existing Categories</h4>
					<ol>
						{this.props.posts.cats.map(cat => {
							return <li key={cat._id}>{cat.category_name}</li>;
						})}
					</ol>

					<h4 style={{ marginTop: '30px' }}>Add Category</h4>
					<Form>
						<Form.Label>Category Name</Form.Label>
						<Form.Control
							type='text'
							placeholder='Category Name - pls do not include any special characters'
							value={this.state.add_category}
							name='add_category'
							onChange={this.handleChange}
						/>
						<br />
						<Button
							onClick={this.handleCategorySubmit}
							variant='outline-info'>
							Add Category
						</Button>
					</Form>
					<hr />
					<h4>Existing Sub-Categories</h4>
					<ol>
						<ol>
							{this.props.posts.cats.map(cat => {
								return cat.subCategories.map(subCat => {
									return (
										<li key={subCat._id}>
											{subCat.sub_category_name} -- under
											category :{' '}
											<span style={{ color: 'red' }}>
												{cat.category_name}
											</span>
										</li>
									);
								});
							})}
						</ol>
					</ol>
					<h4>Add Sub-category</h4>
					<Form>
						<Form.Label>1. Select Category</Form.Label>
						<Form.Control
							as='select'
							name='cat_id'
							value={this.state.cat_id}
							onChange={this.handleChange}>
							<option value=''>Select Category</option>
							{options}
						</Form.Control>

						<br></br>
						<Form.Label>2. Create Sub Category Name</Form.Label>
						<Form.Control
							type='text'
							placeholder='Sub Category Name'
							name='add_sub_category'
							value={this.state.add_sub_category}
							onChange={this.handleChange}
						/>
						<br />
						<Button
							onClick={this.handleSubCatSubmit}
							variant='outline-info'>
							Add Sub Category
						</Button>
					</Form>
				</>
			);
		}
	}
}
const mapStateToProps = state => ({
	posts: state.posts
});
export default connect(mapStateToProps, { fetchCats, addcat, addsubcat })(
	CreateCat
);