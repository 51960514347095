import React, { PureComponent } from 'react'
import AmazonTable from '../UIComps/AmazonTable.js'
import { connect } from 'react-redux'

import { fetchAllPosts, addAsins, removeAsins, addAsinDetails } from '../redux/actions/PostActions'

class Amazon extends PureComponent {
    componentDidMount() {
        let user = JSON.parse(window.localStorage.getItem('user'));
        if (user !== null || undefined) {
            this.props.fetchAllPosts()
        } else {
            this.props.history.push('/adminlogin')
        }

    }
    render() {
        //console.log(this.props.posts)
        let privy = localStorage.getItem('privilege')
        if (privy === 'admin') {
            return (
                <div>
                    <AmazonTable postsArray={this.props.posts} addAsins={this.props.addAsins} removeAsins={this.props.removeAsins} addAsinDetails={this.props.addAsinDetails} />
                </div>
            )
        } else {
            return (
                <div>
                    <h4>Please contact Admin for privileges</h4>
                </div>
            )
        }

    }
}

const mapStateToProps = state => ({
    posts: state.posts.posts
});
export default connect(mapStateToProps, { fetchAllPosts, addAsins, removeAsins, addAsinDetails })((Amazon));
