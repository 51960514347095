

import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
// import FormErrors from '../Utils/FormErrors'
import { toast } from 'react-toastify';
import styled from 'styled-components'
// import queryString from 'query-string';
import { adminLogin } from '../redux/actions/UserActions';
import { connect } from 'react-redux';


class Login extends Component {
    state = {
        user: undefined,
        email: '',
        emailSubmit: '',
        password: '',
        passwordSubmit: '',
        formErrors: { email: '', password: '' },
        emailValid: false,
        passwordValid: false,
        formValid: false,
    };

    resetState = () => {
        this.setState({
            user: undefined,
            email: '',
            emailSubmit: '',
            password: '',
            passwordSubmit: '',
            formErrors: { email: '', password: '' },
            emailValid: false,
            passwordValid: false,
            formValid: false,
        })
    }
    handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    };


    handleSignUp = event => {
        event.preventDefault();
        let dataToSubmit = {
            email: this.state.emailSubmit,
            password: this.state.passwordSubmit
        };

        //console.log(dataToSubmit);
        axios({
            method: 'post',
            url: 'https://api.bestmom.org/admin/signup',
            data: dataToSubmit
        })
            .then(function (response) {
                //console.log(response.data);
                toast.info(
                    'your are registered please login to gain access',
                    { containerId: 'A' }
                );
            })
            .catch(function (error) {
                console.log(error);
            });
        this.resetState()
    };


    handleLogin = event => {
        event.preventDefault();
        let dataToSubmit = {
            email: this.state.email,
            password: this.state.password
        };
        let { history } = this.props
        let { adminLogin } = this.props
        //console.log(dataToSubmit);
        axios({
            method: 'post',
            url: 'https://api.bestmom.org/admin/login',
            data: dataToSubmit
        }).then(function (response) {
            if (response.data.user !== undefined) {
                let user = JSON.stringify(response.data.user)
                //console.log(user)
                adminLogin(JSON.parse(user))
                toast.success('Login Success!', {
                    position: toast.POSITION.TOP_CENTER
                });
                setTimeout(() => {
                    history.push('/');
                }, 2000);
            } else {
                toast.error('login failed please check userID & password')
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
    componentDidMount() {
        let user = JSON.parse(window.localStorage.getItem('user'));
        if (user !== null || undefined) {
            this.setState({
                user: user
            })
            this.props.adminLogin(user);
            toast.success('You are already Logged In!', {
                position: toast.POSITION.TOP_CENTER
            });
            setTimeout(() => {
                this.props.history.push(`/`);
            }, 2000);

        }
    }

    render() {
        return (
            <LoginForm>
                <div className="container login dialog my-5">
                    <div className="row justify-content-center">
                        <div className="col col-md-6">
                            <div className="card">
                                <article className="card-body">
                                    <h3>Login</h3>
                                    <form onSubmit={this.handleLogin}>
                                        <div className="form-group">
                                            <input
                                                name="email"
                                                className="form-control mb-4"
                                                placeholder="Email"
                                                type="email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                name="password"
                                                className="form-control mb-4"
                                                placeholder="password"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-block"
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <h3>SignUp</h3>
                                    <form onSubmit={this.handleSignUp}>
                                        <div className="form-group">
                                            <input
                                                name="emailSubmit"
                                                className="form-control mb-4"
                                                placeholder="Email"
                                                type="email"
                                                value={this.state.emailSubmit}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                name="passwordSubmit"
                                                className="form-control mb-4"
                                                placeholder="password"
                                                type="password"
                                                value={this.state.passwordSubmit}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-block"
                                                    >
                                                        Sign Up
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </LoginForm>)

    }

}

const mapStateToProps = state => ({
    user: state.users
});
export default connect(
    mapStateToProps,
    { adminLogin }
)(Login);

const LoginForm = styled.div`
.circle-icon {
    background:white;
    padding:20px;
    border-radius: 50%;
  }
  .bg1 {background-color: #3b5998}
  .bg2 {background-color: #1da1f2}
  .bg3 {background-color: #ea4335}
  
  .login100-social-item {
    font-size: 25px;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 5px;
  }

  
  .login100-social-item:hover {
    color: #fff;
    background-color: #333333;
    text-decoration : none;
  }
  

`