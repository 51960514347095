import {
	FETCH_ALL_POSTS,
	FETCH_AUTHOR_POSTS,
	FETCH_ARTICLE,
	FETCH_CAT,
	ADD_CAT,
	ARTICLE_VIEW,
	ADD_ASIN,
	REMOVE_ASIN,
	CLEAR_POSTS,
	FETCH_WEEK_DATA,
	MAKE_DRAFT,
	DELETE_ARTICLE,
	RESET_WEEK_DATA,
	FETCH_ALL_MEDICAL
} from './types';
import axios from 'axios';

export const fetchAllPosts = () => {
	return function (dispatch) {
		axios.get('https://api.bestmom.org/article/allpostsAdmin').then((res) => {
			dispatch({
				type: FETCH_ALL_POSTS,
				payload: res.data,
			});
		});
	};
};
export const fetchReviewers = () => {
	return function (dispatch) {
		axios.get('https://api.bestmom.org/api/team/fetchexperts').then((res) => {
			dispatch({
				type: FETCH_ALL_MEDICAL,
				payload: res.data,
			});
		});
	};
};
export const fetchReviewed = () => {
	return function (dispatch) {
		axios.get('https://api.bestmom.org/article/allpostsAdmin?reviewed=true').then((res) => {
			dispatch({
				type: FETCH_ALL_POSTS,
				payload: res.data,
			});
		});
	};
};

export const clearPostsArray = () => {
	return function (dispatch) {
		dispatch({
			type: CLEAR_POSTS,
		});
	};
};

export const addAsins = (articleId, amazonProducts) => {
	return function (dispatch) {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/article/addAsins`,
			data: {
				articleId: articleId,
				amazonProducts: amazonProducts,
			},
		})
			.then((res) => {
				dispatch({
					type: ADD_ASIN,
					payload: res.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
export const addAsinDetails = (amazonProducts) => {
	return function () {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/editorjsapi/addAsin`,
			data: {
				asins: amazonProducts,
			},
		})
			.then((res) => { })
			.catch((err) => {
				console.log(err);
			});
	};
};
export const removeAsins = (articleId, amazonProducts) => {
	return function (dispatch) {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/article/removeAsins`,
			data: {
				articleId: articleId,
				amazonProducts: amazonProducts,
			},
		})
			.then((res) => {
				dispatch({
					type: REMOVE_ASIN,
					payload: res.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const fetchCats = () => {
	return function (dispatch) {
		axios.get(`https://api.bestmom.org/admin/cats`).then((res) => {
			dispatch({
				type: FETCH_CAT,
				payload: res.data,
			});
		});
	};
};
export const fetchWeekData = (week) => {
	return function (dispatch) {
		axios.get(`https://api.bestmom.org/api/weekData/fetch?week_number=${week}`).then((res) => {
			console.log(res.data);
			if (res.data.length > 0) {
				dispatch({
					type: FETCH_WEEK_DATA,
					payload: res.data,
				});
			} else {
				let dataToDispatch = [
					{
						week_number: week,
						summary: [],
						babySize: {
							lengthInCm: '',
							weightInGm: '',
						},
						babyDev: [],
						maternityChanges: [],
						symptoms: [],
						healthCare: [],
						nutrition: [],
						fitness: [],
						checkList: [],
						repImage: [],
						babyDevHigh: [],
						motherDevHigh: [],
						sizeRep: ''
					},
				];
				dispatch({
					type: FETCH_WEEK_DATA,
					payload: dataToDispatch,
				});
			}
		});
	};
};
export const resetWeekData = () => {
	return function (dispatch) {
		dispatch({
			type: RESET_WEEK_DATA,
		});
	};
};

export const addcat = (category_name) => {
	return function (dispatch) {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/admin/addCat`,
			data: { category_name: category_name },
		})
			.then((res) => {
				dispatch({
					type: ADD_CAT,
					payload: res.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const addsubcat = (sub_category_name, category_name) => {
	return function (dispatch) {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/admin/addsubcategory`,
			data: {
				sub_category_name: sub_category_name,
				category_name: category_name,
			},
		})
			.then((res) => {
				dispatch({
					type: ADD_CAT,
					payload: res.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const fetchArticle = (id) => {
	return function (dispatch) {
		axios.get(`https://api.bestmom.org/article/adminposts?_id=${id}`).then((res) => {
			dispatch({
				type: FETCH_ARTICLE,
				payload: res.data,
			});
		});
	};
};
export const deleteArticle = (id) => {
	return function (dispatch) {
		axios.get(`https://api.bestmom.org/article/deletePost?id=${id}`).then((res) => {
			dispatch({
				type: DELETE_ARTICLE,
				payload: res.data,
			});
		});
	};
};

export const articleCounter = (titleUrl) => {
	return function () {
		axios.get(`https://api.bestmom.org/article/postscounter?titleUrl=${titleUrl}`);
	};
};