import {
    ADD_SHOP_PRODUCT
} from './types';

import axios from 'axios';


export const addShopProduct = (data) => {
    return function (dispatch) {
        // console.log(data)
        axios({
            method: 'post',
            url: `https://api.bestmom.org/admin/addShopProduct`,
            data: {
                title: data.title,
                productName: data.productName,
                titleImgUrl: data.titleImgUrl,
                description: data.description,
                minProductPrice: data.minProductPrice,
                maxProductPrice: data.maxProductPrice,
                maxScale: data.maxScale,
                intro: data.intro,
                types: data.types,
                availableBrands : data.availableBrands,
                buyingFactorNames: data.buyingFactorNames
            }
        }).then(res => {
            dispatch({
                type: ADD_SHOP_PRODUCT,
                payload: res.data
            });
        }).catch(err => {
            console.log(err)
        })
    };
};