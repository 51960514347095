import React, { Component } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import axios from 'axios';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Quote from '@editorjs/quote';
import LinkTool from '@editorjs/link';
import Warning from '@editorjs/warning';
import Embed from '@editorjs/embed';
import Checklist from '@editorjs/checklist';
import Table from '@editorjs/table';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import Select from '@mui/material/Select';
// import {
// 	MuiPickersUtilsProvider,
// 	KeyboardDatePicker
// } from '@mui/pickers';

export default class Contests extends Component {
	state = {
		title: '',
		startDate: '',
		endDate: '',
		contestCategory: '',
		img: { jpeg: '', webP: '' },
		titleUrl: '',
		content: [],
		participants: '',
		platform: '',
		prizes: [],
		prizeObject: {},
		editor: null,
		waiting: false
	};
	addContest = () => {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/api/contest/create`,
			data: {
				title: this.state.title,
				startDate: this.state.startDate,
				endDate: this.state.endDate,
				contestCategory: this.state.contestCategory,
				content: this.state.content,
				img: this.state.img,
				platform: this.state.platform
			}
		})
			.then(response => {
				if (response.status === 200) {
					toast.dismiss();
					toast.success(`Success! with id: ${response.data}`, {
						position: toast.POSITION.TOP_CENTER
					});
				} else {
					toast.dismiss();
					toast.error('Server Error!', { containerId: 'A' });
				}
			})
			.catch(function (error) {
				console.log(error.response);
				if (error.response.status === 412) {
					toast.dismiss();
					toast.error(
						'Please change the Title and try saving again (Duplicate Title Error)!',
						{ containerId: 'A' }
					);
				} else {
					toast.dismiss();
					toast.error(
						'save unsuccessful please save the data externally and try again',
						{ containerId: 'A' }
					);
				}
			});
	};
	onChangeImage = e => {
		// this.setState({ uploading: true })
		let uploadedFile = e.target.files[0];
		// const formData = new FormData()
		const formData = new FormData();
		formData.append('image', uploadedFile);
		axios
			.post('https://api.bestmom.org/upload/200pixels', formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then(response => {
				console.log(response);
				this.setState({
					img: {
						jpeg: response.data.file.url,
						webP: response.data.file.webpUrl
					}
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	startSpinner = () => {
		this.setState({
			waiting: true
		});
	};
	stopSpinner = () => {
		this.setState({
			waiting: false
		});
	};
	saveToLocal = () => {
		this.editor
			.save()
			.then(savedData => {
				savedData.blocks = this.validate(savedData);
				this.setState({
					content: savedData.blocks
				});
			})
			.catch(error => {
				console.log(`error in saving : ${error}`);
			});
	};
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	handleDateChange = (date, field) => {
		console.log('this is workin', date, field);
		this.setState({
			[field]: date
		});
	};
	resetData = () => { };
	validate = content => {
		let validData = content.blocks.filter(block => {
			switch (block.type) {
				case 'image':
					if (Boolean(block.data.file.url)) {
						return true;
						break;
					} else {
						return false;
						break;
					}

				case 'paragraph':
					if (Boolean(block.data.text !== '')) {
						return true;
						break;
					} else {
						return false;
						break;
					}
				case 'warning':
					if (Boolean(block.data.message !== '')) {
						return true;
						break;
					} else {
						return false;
						break;
					}

				default:
					return true;
					break;
			}
		});
		return validData;
	};
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
		} else {
			this.props.history.push('/adminlogin');
		}
		this.editor = new EditorJS({
			holder: 'codex-editor',
			tools: {
				header: {
					class: Header,
					inlineToolbar: true
				},
				paragraph: {
					config: {
						placeholder:
							'Describe about the contest. You can add Headers, Images, Tables etc, to make visitors understand about the contest. Please make use of Headers for  rules of the contest, prizes etc, Do not add tite as you need to enter the title in a seperate field'
					}
				},
				image: {
					class: ImageTool,
					inlineToolbar: true,
					config: {
						endpoints: {
							byFile: '/upload'
							// byUrl: '/upload/byUrl',
						}
					}
				},
				quote: {
					class: Quote,
					inlineToolbar: true,
					shortcut: 'CMD+SHIFT+O',
					config: {
						quotePlaceholder: 'Enter a quote',
						captionPlaceholder: "Quote's author"
					}
				},
				linkTool: {
					class: LinkTool,
					inlineToolbar: true,
					config: {
						endpoint: '/editorjsapi/linkfetch'
					}
				},
				list: {
					class: List,
					inlineToolbar: true
				},
				warning: {
					class: Warning,
					inlineToolbar: true,
					shortcut: 'CMD+SHIFT+W',
					config: {
						titlePlaceholder:
							'AVOID/MUST/DO NOT/CAUTION/SUGGESTION',
						messagePlaceholder: 'Message'
					}
				},
				embed: {
					class: Embed,
					inlineToolbar: true,
					config: {
						services: {
							youtube: true,
							coub: true
						}
					}
				},
				checklist: {
					class: Checklist,
					inlineToolbar: true
				},
				table: {
					class: Table,
					inlineToolbar: true
				}
			},
			data: {
				blocks: [
					{
						type: 'paragraph',
						data: {
							text: ''
						}
					}
				]
			},
			onReady: function () { },
			onChange: this.saveToLocal
		});
	}

	render() {
		let StringType = 'string',
			DateType = 'date',
			SelectType = 'select';
		const fields = [
			{
				fieldName: 'title',
				value: this.state.title,
				type: StringType,
				desc: 'Contest Title'
			},
			{
				fieldName: 'contestCategory',
				value: this.state.contestCategory,
				type: StringType,
				desc: 'Contest Category'
			},
			{
				fieldName: 'platform',
				value: this.state.platform,
				type: SelectType,
				desc: 'Platform for Contest'
			},
			{
				fieldName: 'startDate',
				value: this.state.startDate,
				type: DateType,
				desc: 'Contest Start Date'
			},
			{
				fieldName: 'endDate',
				value: this.state.endDate,
				type: DateType,
				desc: 'Contest End Date'
			}
		];
		return (
			<Wrapper waitingDisplay={this.state.waiting}>
				<Modal
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					open={this.state.waiting}>
					<div
						style={{
							background: 'transparent',
							width: '50%',
							margin: '25%',
							height: '100vh',
							paddingLeft: '25vw'
						}}>
						<CircularProgress color='secondary' />
					</div>
				</Modal>
				<h3 style={{ textAlign: 'center', margin: '5px' }}>
					CREATE CONTEST
				</h3>
				<Grid container spacing={4} style={{ margin: '20px' }}>
					{fields.map(field => {
						switch (field.type) {
							case StringType:
								return (
									<Grid item md={4} key={field.fieldName}>
										<TextField
											id='outlined-number'
											name={field.fieldName}
											label={field.desc}
											margin='dense'
											fullWidth
											variant='outlined'
											value={field.value}
											onChange={this.handleChange}
										/>
									</Grid>
								);
							case DateType:
								return (
									<Grid item md={4} key={field.fieldName}>
										{/*<MuiPickersUtilsProvider
											utils={DateFnsUtils}>
											<KeyboardDatePicker
												margin='dense'
												autoOk
												name={field.fieldName}
												label={field.desc}
												value={
													field.value || new Date()
												}
												onChange={date => {
													this.handleDateChange(
														date,
														field.fieldName
													);
												}}
												KeyboardButtonProps={{
													'aria-label': `${field.fieldName}`
												}}
											/>
                                            </MuiPickersUtilsProvider>*/}
									</Grid>
								);
							case SelectType:
								return (
									<Grid item md={4} key={field.fieldName}>
										<InputLabel>{field.desc}</InputLabel>
										<Select
											autoWidth
											name={field.fieldName}
											value={field.value}
											onChange={this.handleChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											<MenuItem value={'instagram'}>
												Instagram
											</MenuItem>
											<MenuItem value={'facebook'}>
												Facebook
											</MenuItem>
											<MenuItem value={'pinterest'}>
												Pinterest
											</MenuItem>
											<MenuItem value={'twitter'}>
												Twitter
											</MenuItem>
										</Select>
									</Grid>
								);
						}
					})}
					<Grid
						container
						item
						xs={12}
						md={4}
						style={{ maxWidth: '200px' }}>
						<p>Main Image 200px</p>
						{this.state.img.jpeg ? (
							<img src={this.state.img.jpeg} width={'100%'} />
						) : (
								<img src='https://via.placeholder.com/200' />
							)}
						<div className='buttons fadein'>
							<div className='button'>
								<input
									type='file'
									id='image'
									onChange={this.onChangeImage}
								/>
							</div>
						</div>
					</Grid>
				</Grid>
				<div className='edit-area' id='codex-editor' />
				<Button
					variant='contained'
					color='primary'
					onClick={this.addContest}
					style={{ width: '100%', marginBottom: '10px' }}>
					Create Contest
				</Button>
			</Wrapper>
		);
	}
}
const Wrapper = styled.div`
	.ingrendientIcon {
		width: 50px;
	}
	.searchBar {
		margin-top: 50px;
		height: 20px;
	}
	.remaining {
		margin-top: 110px;
	}
	.search {
		width: 60%;
		position: absolute;
		display: flex;
	}
	.searchTerm {
		width: 100%;
		border: 3px solid #00b4cc;
		border-right: none;
		padding: 5px;
		height: 40px;
		border-radius: 5px 0 0 5px;
		outline: none;
		color: #9dbfaf;
	}
	.searchTerm:focus {
		color: #00b4cc;
	}
	.searchButton {
		width: 40px;
		height: 40px;
		border: 1px solid #00b4cc;
		background: #00b4cc;
		text-align: center;
		color: #fff;
		border-radius: 0 5px 5px 0;
		cursor: pointer;
		font-size: 20px;
	}
`;