import React, { Component } from 'react';
import RecipeTable from '../UIComps/RecipeTable';
import { connect } from 'react-redux';
import { fetchRecipes, clearRecipes, deleteRecipe } from '../redux/actions/RecipeActions';

class Recipes extends Component {
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
			this.props.fetchRecipes();
		} else {
			this.props.history.push('/adminlogin');
		}
	}
	componentWillUnmount() {
		this.props.clearRecipes();
	}
	render() {
		//console.log(this.props.posts)
		return (
			<div>
				<RecipeTable
					recipesArray={this.props.recipes.recipesArray}
					deleteRecipe={this.props.deleteRecipe}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	recipes: state.recipes
});
export default connect(mapStateToProps, { fetchRecipes, clearRecipes, deleteRecipe })(
	Recipes
);