// import React from 'react';
// import Routes from './Routes';
// import CssBaseline from '@mui/material/CssBaseline';
// import {
//   MuiThemeProvider,
//   createMuiTheme,
//   responsiveFontSizes
// } from '@mui/material';
// import orange from '@mui/icons-material/orange';
// import red from '@mui/icons-material/red';
// import teal from '@mui/icons-material/teal';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import Routes from './Routes';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider , createTheme, responsiveFontSizes } from '@mui/material/styles';
// import orange from '@mui/icons-material';
// import red from '@mui/icons-material';
// import teal from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

// let theme = createTheme({
// 	// palette: {
// 	// 	primary: "#FFA500",
// 	// 	secondary: "#008080"
// 	// },
// 	// status: {
// 	// 	danger: "#FF0000"
// 	// }
// });
let theme = createTheme({
	palette: {
		primary: {
			main: "#FFA500", // Define the main color
		},
		secondary: {
			main: "#008080", // Define the main color
		},
	},
	status: {
		danger: "#FF0000",
	},
});
theme = responsiveFontSizes(theme);

function App() {
	return (
		<ThemeProvider theme={theme}>
		<ToastContainer
		  containerId={'A'}
		  autoClose={4000}
		/>
		<CssBaseline />
		<div className='App'>
		  <Routes />
		</div>
	  </ThemeProvider>
	);
}

export default App;