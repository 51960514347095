import React, { Component } from 'react'
import PostUrlTable from '../UIComps/PostUrlTable'
import { connect } from 'react-redux'
import { fetchAllPosts, fetchCats, clearPostsArray } from '../redux/actions/PostActions'

class ArticleDetails extends Component {
    componentDidMount() {
        let user = JSON.parse(window.localStorage.getItem('user'));
        if (user !== null || undefined) {
            this.props.fetchAllPosts()

        } else {
            this.props.history.push('/adminlogin')
        }

    }
    render() {
        //console.log(this.props.posts)
        let privy = localStorage.getItem('privilege')
        if (privy === 'admin') {
            return (
                <div>
                    <PostUrlTable clearPostsArray={this.props.clearPostsArray} postsArray={this.props.posts} />
                </div>
            )
        } else {
            return (
                <div>
                    <h4>Please contact Admin for privileges</h4>
                </div>
            )
        }

    }
}

const mapStateToProps = state => ({
    posts: state.posts.posts
});
export default connect(mapStateToProps, { fetchAllPosts, fetchCats, clearPostsArray })(ArticleDetails)