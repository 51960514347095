import React, { Component } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import axios from 'axios';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import PollDisplay from './PollDisplay';
import 'react-toastify/dist/ReactToastify.css';

export default class Contests extends Component {
	state = {
		typeOfPoll: '',
		question: '',
		options: [],
		pollCategory: '',
		pollImg: {
			jpeg: '',
			webP: ''
		},
		instruction: '',
		optionPlaceholder: ''
	};
	addOption = () => {
		let optionObj = {
			optionName: this.state.optionPlaceholder,
			voteCount: 0
		};
		this.setState({
			options: [...this.state.options, optionObj],
			optionPlaceholder: ''
		});
	};
	onChangeImage = e => {
		// this.setState({ uploading: true })
		let uploadedFile = e.target.files[0];
		// const formData = new FormData()
		const formData = new FormData();
		formData.append('image', uploadedFile);
		axios
			.post('https://api.bestmom.org/upload/200pixels', formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then(response => {
				console.log(response);
				this.setState({
					pollImg: {
						jpeg: response.data.file.url,
						webP: response.data.file.webpUrl
					}
				});
			})
			.catch(function(error) {
				console.log(error);
			});
	};

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	resetData = () => {
		this.setState({
			typeOfPoll: '',
			question: '',
			options: [],
			pollCategory: '',
			pollImg: {
				jpeg: '',
				webP: ''
			}
		});
	};
	addPoll = () => {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/api/poll/create`,
			data: {
				typeOfPoll: this.state.typeOfPoll,
				question: this.state.question,
				options: this.state.options,
				pollCategory: this.state.pollCategory,
				pollImg: this.state.pollImg,
				instruction: this.state.instruction
			}
		})
			.then(response => {
				if (response.status === 200) {
					toast.dismiss();
					toast.success(`Success! with id: ${response.data}`, {
						position: toast.POSITION.TOP_CENTER
					});
				} else {
					toast.dismiss();
					toast.error('Server Error!', { containerId: 'A' });
				}
			})
			.catch(function(error) {
				console.log(error.response);
				if (error.response.status === 412) {
					toast.dismiss();
					toast.error(
						'Please change the Title and try saving again (Duplicate Title Error)!',
						{ containerId: 'A' }
					);
				} else {
					toast.dismiss();
					toast.error(
						'save unsuccessful please save the data externally and try again',
						{ containerId: 'A' }
					);
				}
			});
	};
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
		} else {
			this.props.history.push('/adminlogin');
		}
	}

	render() {
		let StringType = 'StringType',
			SelectType = 'SelectType';

		const fields = [
			{
				fieldName: 'typeOfPoll',
				value: this.state.typeOfPoll,
				type: SelectType,
				desc: 'Poll Type',
				options: [
					{ value: 'yesno', display: 'Yes/No' },
					{ value: 'choice', display: 'Choice' }
				]
			},
			{
				fieldName: 'question',
				value: this.state.question,
				type: StringType,
				desc: 'Poll Question'
			},
			{
				fieldName: 'pollCategory',
				value: this.state.pollCategory,
				type: StringType,
				desc: 'Category only for internal purpose'
			},
			{
				fieldName: 'instruction',
				value: this.state.instruction,
				type: StringType,
				desc: 'Any Instructions for Poll'
			},
			{
				fieldName: 'optionPlaceholder',
				value: this.state.optionPlaceholder,
				type: StringType,
				desc: 'Add answer options for the question'
			}
		];
		return (
			<Wrapper waitingDisplay={this.state.waiting}>
				<Modal
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					open={this.state.waiting}>
					<div
						style={{
							background: 'transparent',
							width: '50%',
							margin: '25%',
							height: '100vh',
							paddingLeft: '25vw'
						}}>
						<CircularProgress color='secondary' />
					</div>
				</Modal>
				<h3 style={{ textAlign: 'center', margin: '5px' }}>
					CREATE POLL
				</h3>
				<Grid container spacing={4} style={{ margin: '20px' }}>
					{fields.map(field => {
						switch (field.type) {
							case StringType:
								return (
									<Grid item md={4} key={field.fieldName}>
										<TextField
											id='outlined-number'
											name={field.fieldName}
											label={field.desc}
											margin='dense'
											fullWidth
											variant='outlined'
											value={field.value}
											onChange={this.handleChange}
										/>
									</Grid>
								);
							case SelectType:
								return (
									<Grid item md={4} key={field.fieldName}>
										<InputLabel>{field.desc}</InputLabel>
										<Select
											autoWidth
											name={field.fieldName}
											value={field.value}
											onChange={this.handleChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{field.options.map(option => {
												return (
													<MenuItem
														value={option.value}>
														{option.display}
													</MenuItem>
												);
											})}
										</Select>
									</Grid>
								);
						}
					})}
					<Fab
						color='primary'
						aria-label='add'
						onClick={this.addOption}>
						<AddIcon />
					</Fab>
					<Grid
						container
						item
						xs={12}
						md={4}
						style={{ maxWidth: '200px' }}>
						<p>Poll Image 200px</p>
						<div className='buttons fadein'>
							<div className='button'>
								<input
									type='file'
									id='image'
									onChange={this.onChangeImage}
								/>
							</div>
						</div>
					</Grid>
				</Grid>
				<PollDisplay poll={this.state} />
				<Button
					variant='contained'
					color='primary'
					onClick={this.addPoll}
					style={{ width: '100%', marginBottom: '10px' }}>
					Create Poll
				</Button>
			</Wrapper>
		);
	}
}
const Wrapper = styled.div`
	.ingrendientIcon {
		width: 50px;
	}
	.searchBar {
		margin-top: 50px;
		height: 20px;
	}
	.remaining {
		margin-top: 110px;
	}
	.search {
		width: 60%;
		position: absolute;
		display: flex;
	}
	.searchTerm {
		width: 100%;
		border: 3px solid #00b4cc;
		border-right: none;
		padding: 5px;
		height: 40px;
		border-radius: 5px 0 0 5px;
		outline: none;
		color: #9dbfaf;
	}
	.searchTerm:focus {
		color: #00b4cc;
	}
	.searchButton {
		width: 40px;
		height: 40px;
		border: 1px solid #00b4cc;
		background: #00b4cc;
		text-align: center;
		color: #fff;
		border-radius: 0 5px 5px 0;
		cursor: pointer;
		font-size: 20px;
	}
`;