export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const MAKE_USER_ADMIN = 'MAKE_USER_ADMIN';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const SET_ADMIN_USER = 'SET_ADMIN_USER';


export const FETCH_ALL_POSTS = 'FETCH_ALL_POSTS';
export const FETCH_ARTICLE = 'FETCH_ARTICLE';
export const ARTICLE_VIEW = 'ARTICLE_VIEW';
export const FETCH_AUTHOR_POSTS = 'FETCH_AUTHOR_POSTS';
export const ADD_POST = 'ADD_POST';
export const ADD_CAT = 'ADD_CAT';
export const FETCH_CAT = 'FETCH_CAT';
export const ADD_ASIN = 'ADD_ASIN';
export const REMOVE_ASIN = 'REMOVE_ASIN';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const DELETE_RECIPE = 'DELETE_RECIPE';
export const FETCH_MEMBERS_LIST = 'FETCH_MEMBERS_LIST';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const FETCH_WEEK_DATA = 'FETCH_WEEK_DATA';
export const MAKE_DRAFT = 'MAKE_DRAFT';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const RESET_WEEK_DATA = 'RESET_WEEK_DATA';
export const FETCH_ALL_MEDICAL = 'FETCH_ALL_MEDICAL';


/* forum Actions */

export const FETCH_FORUM_CATS = 'FETCH_FORUM_CATS';
export const ADD_FORUM_CATS = 'FETCH_FORUM_CATS';
export const ADD_FORUM_GROUPS = 'FETCH_FORUM_GROUPS';


/* Shop Actions */

export const ADD_SHOP_PRODUCT = 'ADD_SHOP_PRODUCT';


/* Recipe Actions */
export const FETCH_INGREDIENTS = 'FETCH_INGREDIENTS';
export const FETCH_RECIPES = 'FETCH_RECIPES';
export const CLEAR_RECIPES = 'CLEAR_RECIPES';