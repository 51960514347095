import React, { Component } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import TeamMemberDisplay from './TeamMemberDisplay';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import 'react-toastify/dist/ReactToastify.css';
import MembersTable from '../UIComps/MembersTable';
import { connect } from 'react-redux';
import { fetchMembers, deleteMember } from '../redux/actions/UserActions';

class TeamMember extends Component {
	state = {
		memberName: '',
		memberEmail: '',
		photo: { jpeg: '', webP: '' },
		designation: '',
		aboutMember: '',
		qualifications: [],
		department: '',
		facebook: '',
		website: '',
		linkedIn: '',
		twitter: '',
		instagram: '',
		qualifiPlaceholder: ''
	};
	onChangeImage = e => {
		// this.setState({ uploading: true })
		let uploadedFile = e.target.files[0];
		// const formData = new FormData()
		const formData = new FormData();
		formData.append('image', uploadedFile);
		axios
			.post('https://api.bestmom.org/upload/300pixels', formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then(response => {
				console.log(response);
				this.setState({
					photo: {
						jpeg: response.data.file.url,
						webP: response.data.file.webpUrl
					}
				});
			})
			.catch(function(error) {
				console.log(error);
			});
	};
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	addQualification = () => {
		this.setState({
			qualifications: [
				...this.state.qualifications,
				this.state.qualifiPlaceholder
			],
			qualifiPlaceholder: ''
		});
	};
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
			this.props.fetchMembers();
		} else {
			this.props.history.push('/adminlogin');
		}
	}
	addMember = () => {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/api/team/addmember`,
			data: {
				memberName: this.state.memberName,
				memberEmail: this.state.memberEmail,
				photo: this.state.photo,
				designation: this.state.designation,
				aboutMember: this.state.aboutMember,
				qualifications: this.state.qualifications,
				department: this.state.department,
				socialMedia: {
					facebook: this.state.facebook,
					website: this.state.website,
					linkedIn: this.state.linkedIn,
					twitter: this.state.twitter,
					instagram: this.state.instagram
				}
			}
		})
			.then(response => {
				if (response.status === 200) {
					toast.dismiss();
					toast.success(`Success! with id: ${response.data}`, {
						position: toast.POSITION.TOP_CENTER
					});
				} else {
					toast.dismiss();
					toast.error('Server Error!', { containerId: 'A' });
				}
			})
			.catch(function(error) {
				console.log(error.response);
				if (error.response.status === 412) {
					toast.dismiss();
					toast.error('(Duplicate Error)!', { containerId: 'A' });
				} else {
					toast.dismiss();
					toast.error(
						'save unsuccessful please save the data externally and try again',
						{ containerId: 'A' }
					);
				}
			});
		this.props.fetchMembers();
	};
	render() {
		let StringType = 'StringType',
			SelectType = 'SelectType',
			ButtonType = 'ButtonType',
			TextType = 'TextType';
		const fields = [
			{
				fieldName: 'memberName',
				value: this.state.memberName,
				type: StringType,
				desc: 'Person Name'
			},
			{
				fieldName: 'memberEmail',
				value: this.state.memberEmail,
				type: StringType,
				desc: 'Email'
			},
			{
				fieldName: 'designation',
				value: this.state.designation,
				type: StringType,
				desc: 'Designation'
			},
			{
				fieldName: 'qualifiPlaceholder',
				value: this.state.qualifiPlaceholder,
				type: ButtonType,
				desc: 'add  qualification',
				function: () => this.addQualification()
			},
			{
				fieldName: 'aboutMember',
				value: this.state.aboutMember,
				type: TextType,
				desc: 'About'
			},
			{
				fieldName: 'department',
				value: this.state.department,
				type: SelectType,
				desc: 'Medical / Internal',
				options: [
					{ value: 'internal', display: 'internal' },
					{ value: 'medical', display: 'medical' }
				]
			},
			{
				fieldName: 'facebook',
				value: this.state.facebook,
				type: StringType,
				desc: 'facebook link'
			},
			{
				fieldName: 'website',
				value: this.state.website,
				type: StringType,
				desc: 'website address'
			},
			{
				fieldName: 'linkedIn',
				value: this.state.linkedIn,
				type: StringType,
				desc: 'linkedIn link'
			},
			{
				fieldName: 'twitter',
				value: this.state.twitter,
				type: StringType,
				desc: 'twitter link'
			},
			{
				fieldName: 'instagram',
				value: this.state.instagram,
				type: StringType,
				desc: 'instagram link'
			}
		];
		return (
			<>
				<div>
					<h3 style={{ textAlign: 'center' }}>
						Add Team Member / Medical Expert
					</h3>
					<Grid
						container
						direction='column'
						justify='center'
						alignItems='center'>
						<Grid item container style={{ margin: '20px' }}>
							{fields.map(field => {
								switch (field.type) {
									case StringType:
										return (
											<Grid
												item
												md={4}
												key={field.fieldName}>
												<TextField
													id='outlined-number'
													name={field.fieldName}
													label={field.desc}
													margin='dense'
													fullWidth
													variant='outlined'
													value={field.value}
													onChange={this.handleChange}
												/>
											</Grid>
										);
									case TextType:
										return (
											<Grid
												item
												md={4}
												key={field.fieldName}>
												<TextField
													id='outlined-number'
													name={field.fieldName}
													label={field.desc}
													margin='dense'
													fullWidth
													multiline
													rows='4'
													variant='outlined'
													value={field.value}
													onChange={this.handleChange}
												/>
											</Grid>
										);
									case ButtonType:
										return (
											<Grid
												container
												item
												md={8}
												key={field.fieldName}>
												<Grid item md={10}>
													<TextField
														id='outlined-number'
														name={field.fieldName}
														label={field.desc}
														margin='dense'
														fullWidth
														variant='outlined'
														value={field.value}
														onChange={
															this.handleChange
														}
													/>
												</Grid>
												<Grid item md={2}>
													<Fab
														color='secondary'
														aria-label='add'
														onClick={
															field.function
														}>
														<AddIcon />
													</Fab>
												</Grid>
											</Grid>
										);
									case SelectType:
										return (
											<Grid
												item
												md={4}
												key={field.fieldName}>
												<InputLabel>
													{field.desc}
												</InputLabel>
												<Select
													autoWidth
													name={field.fieldName}
													value={field.value}
													onChange={
														this.handleChange
													}>
													<MenuItem value=''>
														<em>None</em>
													</MenuItem>
													{field.options.map(
														option => {
															return (
																<MenuItem
																	key={
																		option.value
																	}
																	value={
																		option.value
																	}>
																	{
																		option.display
																	}
																</MenuItem>
															);
														}
													)}
												</Select>
											</Grid>
										);
								}
							})}
						</Grid>
						<Grid item container style={{ margin: '20px' }}>
							<Grid
								container
								item
								xs={12}
								md={4}
								style={{ maxWidth: '200px' }}>
								<p>Member Image 300px wide 400px length</p>
								<div className='buttons fadein'>
									<div className='button'>
										<input
											type='file'
											id='image'
											onChange={this.onChangeImage}
										/>
									</div>
								</div>
							</Grid>
							<Button
								variant='contained'
								color='primary'
								onClick={this.addMember}
								style={{ width: '100%', margin: '15px' }}>
								Add Member
							</Button>
						</Grid>
						<Grid item container>
							<TeamMemberDisplay member={this.state} />
						</Grid>
					</Grid>

					<Grid>
						{this.props.users.members.length > 0 && (
							<MembersTable
								members={this.props.users.members}
								deleteMember={this.props.deleteMember}
							/>
						)}
					</Grid>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	users: state.users
});
export default connect(mapStateToProps, { fetchMembers, deleteMember })(
	TeamMember
);