// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux'; // Import Provider
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';// Import createStore (you may need to adjust this based on your Redux setup)
// import rootReducer from './redux/reducers/index';
// // Create the Redux store
// const store = createStore(
//   rootReducer,
//   applyMiddleware(thunk) // Apply the thunk middleware
// );
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//   <Provider store={store}> {/* Provide the store */}
//   <App /> {/* Your root component */}
// </Provider>,
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import App from './App';
// import store from './store';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// ReactDOM.render(
// 	<Provider store={store}>
// 		<App />
// 	</Provider>,
// 	document.getElementById('root')
// );

import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import App from './App';
import store from './store';

const root = document.getElementById('root');
const rootElement = (
  <Provider store={store}>
    <App />
  </Provider>
);

if (root) {
  const reactRoot = ReactDOM.createRoot(root);
  reactRoot.render(rootElement);
}