/* reducers should accept action and state and return the new state after executing the action */

import {
    FETCH_FORUM_CATS,
    ADD_FORUM_CATS,
    ADD_FORUM_GROUPS
} from '../actions/types';

const initialState = {
    forum_cats: [],
    forum_cat: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_FORUM_CATS:
            return {
                ...state,
                forum_cats: action.payload
            };
        case ADD_FORUM_CATS:
            return {
                ...state,
                forum_cat: action.payload
            };
        default:
            return state;
    }
}