import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Baby from '@mui/icons-material/ChildCare';
import Preg from '@mui/icons-material/PregnantWoman';
import Symp from '@mui/icons-material/DirectionsWalk';
import Heal from '@mui/icons-material/FitnessCenter';
import Diet from '@mui/icons-material/FreeBreakfast';
import Chec from '@mui/icons-material/AssignmentTurnedIn';
import Summ from '@mui/icons-material/SpeakerNotes';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import BabyDev from './BabyDev';
import BodyChanges from './BodyChanges';
import Summary from './Summary';
import Checklist from './Checklist';
import Health from './Health';
import Nutrition from './Nutrition';
import Symptoms from './Symptoms';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchWeekData, resetWeekData } from '../../redux/actions/PostActions';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

class WeekByWeek extends Component {
	state = {
		week_number: 0,
		summary: [],
		babySize: {
			lengthInCm: '',
			weightInGm: '',
		},
		babyDev: [],
		sizeRep: '',
		maternityChanges: [],
		symptoms: [],
		healthCare: [],
		nutrition: [],
		fitness: [],
		checkList: [],
		tab: 0,
		isLoading: true,
		lengthInCm: '',
		weightInGm: '',
		repImage: [],
		babyDevHigh: [],
		motherDevHigh: [],
		imageType: '',
		imageName: '',
		imageUrl: {
			jpeg: '',
			webP: '',
		},
		babyDevPlaceholder: '',
		motherDevPlaceholder: '',
		babyDevIconPH: {},
		motherDevIconPH: {},
		fetchingDone: false,
	};
	onChangeImageDev = (e) => {
		const appendImage = () => {
			this.setState({
				babyDevHigh: [
					...this.state.babyDevHigh,
					{
						highlight: this.state.babyDevPlaceholder,
						iconUrl: this.state.babyDevIconPH,
					},
				],
				babyDevPlaceholder: '',
				babyDevIconPH: {},
			});
		};
		if (this.state.babyDevPlaceholder !== '') {
			// this.setState({ uploading: true })
			let uploadedFile = e.target.files[0];
			// const formData = new FormData()
			const formData = new FormData();
			formData.append('image', uploadedFile);
			axios
				.post('https://api.bestmom.org/upload/smallIcons', formData, {
					headers: {
						'content-type': 'multipart/form-data',
					},
				})
				.then((response) => {
					console.log(response);
					this.setState({
						babyDevIconPH: {
							jpeg: response.data.file.url,
							webP: response.data.file.webpUrl,
						},
					});
					setTimeout(() => {
						appendImage();
					}, 200);
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			toast.warn('first enter Development Details');
		}
	};
	onChangeImageMothDev = (e) => {
		const appendImage = () => {
			this.setState({
				motherDevHigh: [
					...this.state.motherDevHigh,
					{
						highlight: this.state.motherDevPlaceholder,
						iconUrl: this.state.motherDevIconPH,
					},
				],
				motherDevPlaceholder: '',
				motherDevIconPH: {},
			});
		};
		if (this.state.motherDevPlaceholder !== '') {
			// this.setState({ uploading: true })
			let uploadedFile = e.target.files[0];
			// const formData = new FormData()
			const formData = new FormData();
			formData.append('image', uploadedFile);
			axios
				.post('https://api.bestmom.org/upload/smallIcons', formData, {
					headers: {
						'content-type': 'multipart/form-data',
					},
				})
				.then((response) => {
					console.log(response);
					this.setState({
						motherDevIconPH: {
							jpeg: response.data.file.url,
							webP: response.data.file.webpUrl,
						},
					});
					setTimeout(() => {
						appendImage();
					}, 200);
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			toast.warn('first enter Development Details');
		}
	};
	onChangeImage = (e) => {
		const appendImage = () => {
			this.setState({
				repImage: [
					...this.state.repImage,
					{
						imageType: this.state.imageType,
						imageName: this.state.imageName,
						imageUrl: this.state.imageUrl,
					},
				],
				imageType: '',
				imageName: '',
				imageUrl: {
					jpeg: '',
					webP: '',
				},
			});
		};
		if (this.state.imageType !== '') {
			// this.setState({ uploading: true })
			let uploadedFile = e.target.files[0];
			// const formData = new FormData()
			const formData = new FormData();
			formData.append('image', uploadedFile);
			axios
				.post('https://api.bestmom.org/upload/weekInfo', formData, {
					headers: {
						'content-type': 'multipart/form-data',
					},
				})
				.then((response) => {
					console.log(response);
					this.setState({
						imageUrl: {
							jpeg: response.data.file.url,
							webP: response.data.file.webpUrl,
							mobile: response.data.mobile.url,
							mobileWebP: response.data.mobile.webpUrl,
						},
					});
					setTimeout(() => {
						appendImage();
					}, 200);
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			toast.warn('first enter Type of Image');
		}
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handleTabs = (event, newValue) => {
		this.setState({
			tab: newValue,
		});
	};
	resetData = () => {
		this.setState({
			week_number: null,
			summary: [],
			babySize: {
				lengthInCm: '',
				weightInGm: '',
			},
			babyDev: [],
			sizeRep: '',
			maternityChanges: [],
			symptoms: [],
			healthCare: [],
			nutrition: [],
			fitness: [],
			checkList: [],
			tab: 0,
			isLoading: true,
			lengthInCm: '',
			weightInGm: '',
			weekInfographic: {
				jpeg: '',
				webP: '',
			},
		});
	};
	submitData = () => {
		const goBack = () => {
			this.props.history.push('/');
		};
		if (this.state.fetchingDone) {
			let dataToSubmit = {
				week_number: this.state.week_number,
				summary: this.state.summary,
				babySize: {
					lengthInCm:
						this.state.babySize.lengthInCm === ''
							? this.state.lengthInCm
							: this.state.babySize.lengthInCm,
					weightInGm:
						this.state.babySize.weightInGm === ''
							? this.state.weightInGm
							: this.state.babySize.weightInGm,
				},
				babyDev: this.state.babyDev,
				maternityChanges: this.state.maternityChanges,
				symptoms: this.state.symptoms,
				sizeRep: this.state.sizeRep,
				healthCare: this.state.healthCare,
				nutrition: this.state.nutrition,
				fitness: this.state.fitness,
				checkList: this.state.checkList,
				repImage: this.state.repImage,
				babyDevHigh: this.state.babyDevHigh,
				motherDevHigh: this.state.motherDevHigh,
			};
			axios({
				method: 'post',
				url: `https://api.bestmom.org/api/weekData/add`,
				data: dataToSubmit,
			})
				.then((response) => {
					if (response.status === 200) {
						toast.dismiss();
						toast.success(`Success! with id: ${response.data}`, {
							position: toast.POSITION.TOP_CENTER,
						});
						setTimeout(() => {
							goBack();
						}, 2000);
					} else {
						toast.dismiss();
						toast.error('Server Error!', { containerId: 'A' });
					}
				})
				.catch(function (error) {
					console.log(error.response);
					if (error.response.status === 412) {
						toast.dismiss();
						toast.error(
							'Please change the Title and try saving again (Duplicate Title Error)!',
							{ containerId: 'A' }
						);
					} else {
						toast.dismiss();
						toast.error(
							'save unsuccessful please save the data externally and try again',
							{ containerId: 'A' }
						);
					}
				});
			this.resetData();
		} else {
			toast.warn('waiting for fetched data to load');
		}
	};
	componentDidMount() {
		let week = this.props.match.params.week_number;
		this.setState({
			week_number: week,
		});
		this.props.fetchWeekData(week);
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.weekData.length !== this.props.weekData.length) {
			if (this.props.weekData.length > 0) {
				let weekData = this.props.weekData[0];
				this.setState({
					babySize: weekData.babySize,
					summary: weekData.summary,
					babyDev: weekData.babyDev,
					sizeRep: weekData.sizeRep,
					maternityChanges: weekData.maternityChanges,
					symptoms: weekData.symptoms,
					healthCare: weekData.healthCare,
					nutrition: weekData.nutrition,
					fitness: weekData.fitness,
					checkList: weekData.checkList,
					repImage: weekData.repImage,
					babyDevHigh: weekData.babyDevHigh,
					motherDevHigh: weekData.motherDevHigh,
					isLoading: false,
				});
				if (this.props.didWeekDataLoad) {
					setTimeout(() => {
						console.log('setting state');
						this.setState({
							fetchingDone: true,
						});
					}, 2000);
				}
			}
		}
		if (
			prevProps.match.params.week_number !==
			this.props.match.params.week_number
		) {
			let week = this.props.match.params.week_number;
			this.setState({
				week_number: week,
			});
			this.props.fetchWeekData(week);
		}
	}
	componentWillUnmount() {
		this.resetData();
		this.props.resetWeekData()
	}
	render() {
		if (this.props.weekData.length > 0) {
			return (
				<Grid
					container
					direction='column'
					justify='center'
					alignItems='center'>
					<Grid
						item
						container
						direction='row'
						justify='center'
						alignItems='center'>
						<TextField
							id='standard-number'
							disabled
							label='Week'
							type='number'
							name='week_number'
							value={this.state.week_number}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							id='standard-number'
							label='baby length'
							type='number'
							name='lengthInCm'
							value={
								this.state.babySize.lengthInCm === ''
									? this.state.lengthInCm
									: this.state.babySize.lengthInCm
							}
							onChange={this.handleChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							id='standard-number'
							label='baby weight'
							type='number'
							name='weightInGm'
							value={
								this.state.babySize.weightInGm === ''
									? this.state.weightInGm
									: this.state.babySize.weightInGm
							}
							onChange={this.handleChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justify='center'
						alignItems='center'>
						<Avatar src={`/upload/week/week-${this.state.week_number}.svg`} />
					</Grid>
					<Grid
						item
						container
						direction='row'
						justify='center'
						alignItems='center'>
						<TextField
							id='standard-basic'
							label='baby is about the size of -------'
							name='sizeRep'
							fullWidth
							value={this.state.sizeRep}
							onChange={this.handleChange}
						/>
						{/* <Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							name='imageType'
							value={this.state.imageType}
							displayEmpty
							onChange={this.handleChange}>
							<MenuItem value=''>
								<em>None</em>
							</MenuItem>
							<MenuItem value={'fruits'}>Fruits</MenuItem>
							<MenuItem value={'sports'}>Sports</MenuItem>
						</Select>
						<TextField
							id='standard-basic'
							label='baby is about the size of -------'
							name='imageName'
							fullWidth
							value={this.state.imageName}
							onChange={this.handleChange}
						/>

						<div className='buttons fadein'>
							<div className='button'>
								<input
									type='file'
									id='image'
									name='repIcon'
									onChange={this.onChangeImage}
								/>
							</div>
						</div> */}
					</Grid>
					<Grid
						item
						container
						direction='column'
						justify='center'
						alignItems='center'>
						<h4>Development Highlights</h4>
						<Grid item xs={6} container direction='column'>
							<h5>Baby Dev Hightlights :</h5>
							{this.state.babyDevHigh.map((high) => ((
								<TableRow hover>
									<TableCell align='center'>
										<p>
											{
												high.highlight
											}
										</p>
									</TableCell>
									<TableCell align='center'>
										<Avatar
											src={
												high
													.iconUrl
													.jpeg
											}
											alt={
												high.highlight
											}
											style={{
												marginTop:
													'15px'
											}}
										/>
									</TableCell>
									<TableCell><DeleteIcon onClick={this.deleteHighlight} /></TableCell>
								</TableRow>
							)))}
							<TextField
								id='standard-textarea'
								label='Baby Development hightlight'
								placeholder='Highlight for Baby Dev'
								multiline
								name='babyDevPlaceholder'
								value={this.state.babyDevPlaceholder}
								onChange={this.handleChange}
							/>
							<div className='buttons fadein'>
								<div className='button'>
									<input
										type='file'
										id='image'
										label='icon representing the baby dev'
										name='babyDevIcon'
										onChange={this.onChangeImageDev}
									/>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} container direction='column'>
							<h5>Mother Dev Hightlights :</h5>
							<TextField
								id='standard-textarea'
								label='Mother Development hightlight'
								placeholder='Highlight for Mother Dev'
								multiline
								name='motherDevPlaceholder'
								value={this.state.motherDevPlaceholder}
								onChange={this.handleChange}
							/>
							<div className='buttons fadein'>
								<div className='button'>
									<input
										type='file'
										id='image'
										label='icon representing the mother dev'
										onChange={this.onChangeImageMothDev}
									/>
								</div>
							</div>
						</Grid>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justify='center'
						alignItems='center'>
						<Paper square>
							<Tabs
								value={this.state.tab}
								onChange={this.handleTabs}
								variant='fullWidth'
								// variant='scrollable'
								scrollButtons='on'
								indicatorColor='secondary'
								textColor='secondary'
								aria-label='icon label tabs example'>
								<Tab icon={<Summ />} label='Summary'></Tab>
								<Tab icon={<Preg />} label='Body Changes'></Tab>
								<Tab
									icon={<Baby />}
									label='Baby Development'></Tab>
								<Tab icon={<Symp />} label='Symptoms'></Tab>
								<Tab
									icon={<Heal />}
									label={'Health & Fitness'}></Tab>
								<Tab icon={<Diet />} label='Nutrition'></Tab>
								<Tab icon={<Chec />} label='Checklist'></Tab>
							</Tabs>
							<TabPanel value={this.state.tab} index={0}>
								<Summary
									week={this.state.week_number}
									data={this.state.summary}
									tabIndex={() => {
										this.setState({
											tab: this.state.tab + 1,
										});
									}}
									changeFunction={(data) => {
										this.setState({
											summary: data.blocks,
										});
									}}
									fetchDone={this.fetchDone}
								/>
							</TabPanel>
							<TabPanel value={this.state.tab} index={1}>
								<BodyChanges
									week={this.state.week_number}
									data={this.state.maternityChanges}
									tabIndex={() => {
										this.setState({
											tab: this.state.tab + 1,
										});
									}}
									changeFunction={(data) => {
										this.setState({
											maternityChanges: data.blocks,
										});
									}}
								/>
							</TabPanel>
							<TabPanel value={this.state.tab} index={2}>
								<BabyDev
									week={this.state.week_number}
									data={this.state.babyDev}
									tabIndex={() => {
										this.setState({
											tab: this.state.tab + 1,
										});
									}}
									changeFunction={(data) => {
										this.setState({
											babyDev: data.blocks,
										});
									}}
								/>
							</TabPanel>
							<TabPanel value={this.state.tab} index={3}>
								<Symptoms
									week={this.state.week_number}
									data={this.state.symptoms}
									tabIndex={() => {
										this.setState({
											tab: this.state.tab + 1,
										});
									}}
									changeFunction={(data) => {
										this.setState({
											symptoms: data.blocks,
										});
									}}
								/>
							</TabPanel>
							<TabPanel value={this.state.tab} index={4}>
								<Health
									week={this.state.week_number}
									data={this.state.healthCare}
									tabIndex={() => {
										this.setState({
											tab: this.state.tab + 1,
										});
									}}
									changeFunction={(data) => {
										this.setState({
											healthCare: data.blocks,
										});
									}}
								/>
							</TabPanel>
							<TabPanel value={this.state.tab} index={5}>
								<Nutrition
									week={this.state.week_number}
									data={this.state.nutrition}
									tabIndex={() => {
										this.setState({
											tab: this.state.tab + 1,
										});
									}}
									changeFunction={(data) => {
										this.setState({
											nutrition: data.blocks,
										});
									}}
								/>
							</TabPanel>
							<TabPanel value={this.state.tab} index={6}>
								<Checklist
									week={this.state.week_number}
									data={this.state.checkList}
									tabIndex={() => {
										console.log('last tab');
									}}
									changeFunction={(data) => {
										this.setState({
											checkList: data.blocks,
										});
									}}
								/>
							</TabPanel>
						</Paper>
					</Grid>
					<Button
						variant='contained'
						color='primary'
						type='submit'
						onClick={this.submitData}>
						Submit
					</Button>
				</Grid>
			);
		} else {
			return <p>Loading...</p>;
		}
	}
}
const mapStateToProps = (state) => ({
	weekData: state.posts.weekData,
	didWeekDataLoad: state.posts.didWeekDataLoad,
});
export default connect(mapStateToProps, { fetchWeekData, resetWeekData })(WeekByWeek);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};