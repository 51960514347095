import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
// import NavigationBar from './NavigationBar';
import SideMenu from './SideMenu';

export default function Routes() {
	return (
		<div>
			<Router>
				{/* <NavigationBar /> */}
				<SideMenu />
			</Router>
		</div>
	);
}