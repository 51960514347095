import React from 'react';
import { withRouter } from 'react-router-dom';
// Import React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import DeleteIcon from '@mui/icons-material/Delete';

class RecipeTable extends React.Component {
	state = {
		sorted: [],
		page: 0,
		pageSize: 10,
		expanded: {},
		resized: [],
		filtered: [],
		data: [],
		header: 'RECIPES LIST'
	};
	componentDidMount() {
		let recipesArray = this.props.recipesArray;
		this.setState({
			data: recipesArray,
			header: 'ALL POSTS'
		});
	}
	componentWillUnmount() {
		// this.props.clearPostsArray()
	}

	resetState = () => {
		this.setState({
			sorted: [],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			data: [],
			header: 'ALL POSTS'
		});
	};
	makeData = (data, e) => {
		this.setState({
			data: data,
			header: `${e.target.innerHTML}`
		});
	};

	render() {
		const { data } = this.state;
		const { header } = this.state;
		let recipesArray = this.props.recipesArray;
		// let draftsArray = postsArray.filter((draft) => {
		//     return draft.draft === true
		// })
		// let publishedArray = postsArray.filter((post) => {
		//     return post.published === true
		// })
		// let waitingTBP = postsArray.filter((post) => {
		//     return post.waitingTBP === true
		// })
		return (
			<div>
				<div>
					<button onClick={e => this.makeData(recipesArray, e)}>
						Recipes
					</button>
					<button onClick={this.resetState}>Reset State</button>
				</div>
        { /*ReactTable*/}
				<ReactTable
					data={data}
					noDataText='Please Click on the buttons to populate Data'
					columns={[
						{
							Header: `${header}`,
							columns: [
								{
									Header: 'TITLE',
									accessor: 'recipeTitle',
									filterMethod: (filter, row) => {
										if (
											String(row[filter.id])
												.toLowerCase()
												.includes(
													filter.value.toLowerCase()
												)
										) {
											return true;
										}
									}
								},
								{
									Header: 'RECIPE CATEGORY',
									accessor: 'category'
								},
								{
									Header: 'DISH TYPE',
									accessor: 'dishType'
								},
								{
									Header: 'DELETE RECIPE',
									Cell: () => (
										<div style={{ textAlign: 'center' }}>
											<DeleteIcon />
										</div>
									)
								}
							]
						}
					]}
					filterable
					defaultPageSize={10}
					className='-striped -highlight'
					// Controlled props
					sorted={this.state.sorted}
					page={this.state.page}
					pageSize={this.state.pageSize}
					expanded={this.state.expanded}
					resized={this.state.resized}
					filtered={this.state.filtered}
					// Callbacks
					onSortedChange={sorted => this.setState({ sorted })}
					onPageChange={page => this.setState({ page })}
					onPageSizeChange={(pageSize, page) =>
						this.setState({ page, pageSize })
					}
					onExpandedChange={expanded => this.setState({ expanded })}
					onResizedChange={resized => this.setState({ resized })}
					onFilteredChange={filtered => this.setState({ filtered })}
					getTdProps={(state, rowInfo, column, instance) => {
						return {
							onClick: (e, handleOriginal) => {
								if (column.Header === 'TITLE') {
									this.props.history.push({
										pathname: `/editRecipe/${rowInfo.original._id}`,
										state: rowInfo.original
									});
								}
								if (column.Header === 'DELETE RECIPE') {
									this.props.deleteRecipe(
										rowInfo.original.titleUrl
									);
								}
								console.log(
									'It was in this table instance:',
									instance
								);
								if (handleOriginal) {
									handleOriginal();
								}
							}
						};
					}}
				/>
			</div>
		);
	}
}

export default withRouter(RecipeTable);