import React, { Component } from 'react'
import { connect } from 'react-redux'
import { adminLogin } from '../redux/actions/UserActions'

class Home extends Component {
    componentDidMount() {
        let user = JSON.parse(window.localStorage.getItem('user'));
        if (!user) {
            this.props.history.push('/adminlogin');
        }
    }
    render() {
        let user = JSON.parse(window.localStorage.getItem('user'));
        let privy = localStorage.getItem('privilege');
        if (user) {
            if (user.admin) {
                return (
                    <div>
                        <h1>Hi, {this.props.users.user.userName}</h1>
                    </div>
                )
            } else {
                return (
                    <div>
                        <h1>Hi, {user.userName}</h1>
                        <p>You are having Previliges of a {privy}</p>
                        <p>If you do not see menu options for {privy} - Please refresh the page</p>
                        <p>In case you need other priviliges , Please contact admin : preeti@bestmom.org / +919339330133</p>
                    </div>
                )
            }
        } else {
            return (<h2>Please login ..</h2>)
        }


    }
}

const mapStateToProps = state => ({
    users: state.users
});
export default connect(mapStateToProps, { adminLogin })(Home)