import { combineReducers } from 'redux';
import postsReducer from './PostReducer';
import userReducer from './userReducer';
import forumReducer from './forumReducer';
import recipeReducer from './recipeReducer';


const rootReducer = combineReducers({
	users: userReducer,
	posts: postsReducer,
	forumReducer: forumReducer,
	recipes: recipeReducer,
	// sidebar: sidebarReducer
});

export default rootReducer;