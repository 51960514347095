import React, { Component } from 'react';
import UsersTable from '../UIComps/UsersTable';
import { connect } from 'react-redux';
import { fetchUsers } from '../redux/actions/UserActions';

class Users extends Component {
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
			this.props.fetchUsers();
		} else {
			this.props.history.push('/adminlogin');
		}
	}
	render() {
		if (this.props.users.usersList.length > 0) {
			return (
				<div>
					<UsersTable usersArray={this.props.users.usersList} />
				</div>
			);
		} else {
			return <h5>Loading ...</h5>;
		}
	}
}

const mapStateToProps = state => ({
	users: state.users
});
export default connect(mapStateToProps, { fetchUsers })(Users);