import {
	FETCH_USER_LIST,
	SET_ADMIN_USER,
	ADMIN_LOGOUT,
	FETCH_MEMBERS_LIST,
	DELETE_MEMBER
} from './types';
import axios from 'axios';

export const fetchUsers = () => {
	return function (dispatch) {
		axios.get(`https://api.bestmom.org/admin/users`).then(res => {
			dispatch({
				type: FETCH_USER_LIST,
				payload: res.data.data
			});
		});
	};
};
export const fetchMembers = () => {
	return function (dispatch) {
		axios.get(`https://api.bestmom.org/admin/members`).then(res => {
			dispatch({
				type: FETCH_MEMBERS_LIST,
				payload: res.data.data
			});
		});
	};
};
export const deleteMember = emailID => {
	return function (dispatch) {
		axios.post(`https://api.bestmom.org/admin/members/delete?memberEmail=${emailID}`).then(res => {
			dispatch({
				type: DELETE_MEMBER,
				payload: res.data.data
			});
		});
	};
};
export const adminLogin = (user) => {
	console.log(user)
	return function (dispatch) {
		dispatch({
			type: SET_ADMIN_USER,
			payload: user
		});
	};
};

export const userLogout = () => {
	return function (dispatch) {
		dispatch({
			type: ADMIN_LOGOUT
		});
	};
};