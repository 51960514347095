import React from 'react';
import Editor from '../EditorjsComponent/Editor';

export default function Summary({
	week,
	data,
	changeFunction,
	tabIndex,
}) {

	return (
		<div>
			<Editor
				data={data}
				changeFunction={changeFunction}
				tabIndex={tabIndex}
			/>
		</div>
	);
}