import React, { Component } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Quote from '@editorjs/quote';
import LinkTool from '@editorjs/link';
import Warning from '@editorjs/warning';
import Embed from '@editorjs/embed';
import Checklist from '@editorjs/checklist';
import Table from '@editorjs/table';
import axios from 'axios';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { fetchCats, fetchArticle, fetchReviewers } from '../redux/actions/PostActions';
import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';



const styles = (theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #fff',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
});

class PostApprovalPage extends Component {
	state = {
		articleId: '',
		author: undefined,
		reviewedBy: '',
		data: '',
		category: '',
		subCategory: '',
		savedData: '',
		notifyEmail: '',
		message: '',
		title: '',
		modalIsOpen: false,
		editor: null
	}

	review = () => {
		toast.warn('Please make sure you save before sending')
		axios({
			method: 'post',
			url: `https://api.bestmom.org/editorjsapi/reviewed?articleId=${this.state.articleId}`,
		})
			.then((response) => {
				if (response.status === 200) {
					toast.success('the article is reviewed but pending to be Published');
				} else {
					toast.error('server error!', {
						position: toast.POSITION.TOP_CENTER,
					});
				}
			})
			.catch(function (error) {
				console.log(error);
				toast.error('server error')
			});
	};
	sendForPublish = () => {
		axios({
			method: 'post',
			url: `https://api.bestmom.org/editorjsapi/readyToPublish?articleId=${this.state.articleId}`,
		})
			.then((response) => {
				if (response.status === 200) {
					toast.success('the article is approved by you and waiting to be Published by Admin');
					setTimeout(() => {
						this.props.history.push('/articles')
					}, 3000);

				} else {
					toast.error('server error!', {
						position: toast.POSITION.TOP_CENTER,
					});
				}
			})
			.catch(function (error) {
				console.log(error);
				toast.error('server error please inform admin')
			});

	};
	approve = () => {
		this.editor.save().then((savedData) => {
			this.setState({
				savedData: savedData,
			});
			axios({
				method: 'post',
				url: `https://api.bestmom.org/editorjsapi/publish`,
				data: {
					savedData: this.state.savedData,
					articleId: this.state.articleId,
					category: this.state.category,
					subCategory: this.state.subCategory,
					reviewedBy: this.state.reviewedBy,
				},
			})
				.then((response) => {
					if (response.status === 200) {
						toast.dismiss();
						toast.success('Success!', {
							position: toast.POSITION.TOP_CENTER,
						});
					} else {
						toast.error('server error!', {
							position: toast.POSITION.TOP_CENTER,
						});
					}
				})
				.catch(function (error) {
					console.log(error);
					toast(
						'save unsuccessful please save the data externally and contact Admin!',
						{ containerId: 'A' }
					);
				});
			toast.success('the article is approved');
			setTimeout(() => {
				this.props.history.push('/articles');
			}, 2000);
		});
	};
	requestMods = () => {
		if (this.state.articleId) {
			this.setState({ modalIsOpen: true });
		}
	};
	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};
	sendRequest = () => {
		if (this.state.message && this.state.notifyEmail) {
			let data = {
				articleId: this.state.articleId,
				notifyEmail: this.state.notifyEmail,
				message: this.state.message,
				title: this.state.title,
			};

			axios({
				method: 'post',
				url: `https://api.bestmom.org/editorjsapi/make-draft`,
				data: data,
			}).then((res) => {
				this.setState({ modalIsOpen: false });
				if (res.status === 200) {
					toast.success('Article is in Draft Status')
				}
			});
		} else {
			this.setState({ modalIsOpen: false });
			toast.warn('message or email not available');
		}
	};
	saveToLocal = () => {
		this.editor.save().then(async (savedData) => {
			this.setState({
				savedData: savedData,
			});
		});
	};
	modify = () => {
		this.editor.save().then(async (savedData) => {
			this.setState({
				savedData: savedData,
			});
			axios({
				method: 'post',
				url: `https://api.bestmom.org/editorjsapi/updatedraft`,
				data: {
					savedData: this.state.savedData,
					articleId: this.state.articleId,
					category: this.state.category,
					subCategory: this.state.subCategory,
					reviewedBy: this.state.reviewedBy,
				},
			})
				.then((response) => {
					if (response.status === 200) {
						console.log(response);
						toast.dismiss();
						toast.success('Success!', {
							position: toast.POSITION.TOP_CENTER,
						});

					} else {
						toast.error(
							`'server not responding' response code :${response.status}`,
							{ containerId: 'A' }
						);
					}
				})
				.catch(function (error) {
					console.log(error);
					toast(
						'save unsuccessful please save the data externally and contact Admin!',
						{ containerId: 'A' }
					);
				});
		});
	};
	selectCategory = (e) => {
		let category = e.target.value;
		this.setState({
			category: `${category}`,
		});
	};
	selectMedical = (e) => {
		this.setState({
			reviewedBy: e.target.value,
		});
	};
	selectSubCategory = (e) => {
		this.setState({
			subCategory: e.target.value,
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	componentDidMount() {
		console.log('component mounted')
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (!user) {
			this.props.history.push('/adminlogin');
		}
		this.props.fetchCats();
		let articleId = this.props.match.params.id;
		console.log(articleId)
		this.props.fetchReviewers();
		this.props.fetchArticle(articleId);

	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.articleId !== this.props.articleId) {
			let data = this.props.article[0]
			console.log(data);
			this.setState({
				data: data.content,
				articleId: data._id,
				author: data.author._id,
				category: data.category._id,
				subCategory: data.subCategory._id,
				reviewedBy: data.reviewedBy,
				title: data.title,
				notifyEmail: data.author.email
			});
		}
		if (prevState.data !== this.state.data) {
			this.editor = new EditorJS({
				holder: 'codex-editor',
				autofocus: true,
				tools: {
					header: {
						class: Header,
						inlineToolbar: true,
						config: {
							placeholder: 'Article Title',
						},
					},
					image: {
						class: ImageTool,
						inlineToolbar: true,
						config: {
							endpoints: {
								byFile: '/upload',
							},
						},
					},
					quote: {
						class: Quote,
						inlineToolbar: true,
						shortcut: 'CMD+SHIFT+O',
						config: {
							quotePlaceholder: 'Enter a quote',
							captionPlaceholder: "Quote's author",
						},
						// ...
					},
					linkTool: {
						class: LinkTool,
						inlineToolbar: true,
						config: {
							endpoint: '/editorjsapi/linkfetch',
						},
					},
					list: {
						class: List,
						inlineToolbar: true,
					},
					warning: {
						class: Warning,
						inlineToolbar: true,
						shortcut: 'CMD+SHIFT+W',
						config: {
							titlePlaceholder: 'Title',
							messagePlaceholder: 'Message',
						},
					},
					embed: {
						class: Embed,
						inlineToolbar: true,
					},
					checklist: {
						class: Checklist,
						inlineToolbar: true,
					},
					table: {
						class: Table,
						inlineToolbar: true,
					},
				},
				data: {
					blocks: this.state.data,
				},
				onReady: function () { },
				onChange: this.saveToLocal,
			});
		}


	}
	render() {
		let privy = localStorage.getItem('privilege')
		let isAdmin = Boolean(privy === 'admin')
		const { classes } = this.props;
		let medicOptions = this.props.medicalReviewers.map((reviewer) => {
			return (
				<option key={reviewer._id} value={reviewer._id}>
					{reviewer.memberName}
				</option>
			);
		});
		let catOptions = this.props.posts.cats.map((cat) => {
			return (
				<option key={cat._id} value={cat._id}>
					{cat.category_name}
				</option>
			);
		});
		let subCats = this.props.posts.cats.filter((cat) => {
			return cat._id === this.state.category;
		});
		let SubCatOptions = subCats.map((cat) => {
			return cat.subCategories.map((subCat) => {
				return (
					<option key={subCat._id} value={subCat._id}>
						{subCat.sub_category_name}
					</option>
				);
			});
		});


		return (
			<Article>
				<Button variant="contained" color="primary" style={{ margin: '5px' }} onClick={this.modify}>Save</Button>
				{isAdmin ? (<>
					<Button variant="outlined" color="primary" style={{ margin: '5px' }} onClick={this.requestMods}>
						Request Modifications
				</Button>
					<Button variant="contained" color="secondary" style={{ margin: '5px' }} onClick={this.review}>REVIEWED</Button>
					<>
						<Button variant="contained" color="secondary" style={{ margin: '5px' }} onClick={this.sendForPublish}>Send for Publishing</Button>
					</>
					<Button variant="contained" color="secondary" style={{ margin: '5px' }} onClick={this.approve}>PUBLISH</Button>
				</>) : (
						<>
							<Button variant="contained" color="secondary" style={{ margin: '5px' }} onClick={this.sendForPublish}>Send for Publishing</Button>
						</>
					)}


				<Form style={{ marginTop: '40px' }}>
					<Row>
						<Col>
							<Form.Control
								as='select'
								value={this.state.category}
								onChange={this.selectCategory}>
								<option value=''>Please select Category</option>
								{catOptions}
							</Form.Control>
						</Col>
						<Col>
							<Form.Control
								as='select'
								value={this.state.subCategory}
								onChange={this.selectSubCategory}>
								<option value=''>
									Please select Sub-Category
								</option>
								{SubCatOptions}
							</Form.Control>
						</Col>
						<Col>
							<Form.Control
								as='select'
								value={this.state.reviewedBy}
								onChange={this.selectMedical}>
								<option value=''>
									Please select Medical Reviewer
								</option>
								{medicOptions}
							</Form.Control>
						</Col>
					</Row>
				</Form>
				<div className='edit-area' id='codex-editor' />
				<Dialog
					open={this.state.modalIsOpen}
					onClose={this.closeModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">REQUEST MODIFICATIONS</DialogTitle>
					<DialogContent>
						<TextField
							placeholder='What Modifications are required'
							value={this.state.message}
							onChange={this.handleChange}
							name='message'
							fullWidth
							multiline
							rowsMax='4'
						/>
					</DialogContent>
					<DialogActions>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							onClick={this.sendRequest}
							color='primary'
							className={classes.submit}>
							Send Request
							</Button>
					</DialogActions>
				</Dialog>
			</Article>
		);
	}
}
const mapStateToProps = (state) => ({
	posts: state.posts,
	article: state.posts.article,
	articleId: state.posts.id,
	medicalReviewers: state.posts.medicalReviewers
});
export default withStyles(styles)(
	connect(mapStateToProps, { fetchCats, fetchArticle, fetchReviewers })(PostApprovalPage)
);

const Article = styled.div`
	h1 {
		color: #ff2462;
		text-align: center;
		font-family: Nunito;
		font-size: 52px;
		font-weight : 900;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 300px 100px;
	}
	.ce-paragraph {
		color: #595a5c;
		text-align: justify;
		font-family: Raleway:300;
		font-size: 20px;
	}
	h2 {
		color: #7300ff;
		text-align: left;
		font-family: Nunito;
		font-size: 36px;

	}
	h3 {
		color: #45824c;
		text-align: left;
		font-family: Nunito;
		font-size: 30px;
	}
	.center {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 80%;
	}
`;