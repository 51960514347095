import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import {
	fetchforumcats,
	addforumcat,
	addforumgroup
} from '../../redux/actions/forumActions';
import { toast } from 'react-toastify';

class CreateCat extends Component {
	state = {
		Category_name: '',
		Category_Color: '',
		Category_Description: '',
		add_forum_group: '',
		fourm_cat_id: ''
	};
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
			this.props.fetchforumcats();
		} else {
			this.props.history.push('/adminlogin');
		}
	}
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	resetState = () => {
		this.setState({
			Category_name: '',
			Category_Color: '',
			Category_Description: '',
			add_forum_group: '',
			fourm_cat_id: ''
		});
	};
	handleCategorySubmit = () => {
		if (this.state.Category_name !== null || undefined) {
			let data = {
				Category_name: this.state.Category_name.toLowerCase(),
				Category_Color: this.state.Category_Color,
				Category_Description: this.state.Category_Description
			};
			this.props.addforumcat(data);
			toast.success('Success!', {
				position: toast.POSITION.TOP_CENTER
			});
			this.resetState();
		} else {
			toast('unsuccessful! - please enter the data');
		}
	};
	// handleSubCatSubmit = () => {
	//     if (this.state.addforumgroup !== null || undefined) {
	//         let subcat = this.state.add_sub_category.toLowerCase()
	//         this.props.addsubcat(subcat, this.state.cat_id)
	//         toast.success()
	//     } else {
	//         toast(
	//             'unsuccessful! - please enter the data',
	//         )

	//     }
	// }
	render() {
		// let options;
		// if (this.props.posts.cats !== undefined) {
		//     options = this.props.posts.cats.map((cat) => {
		//         return (<option key={cat._id} value={cat._id}>{cat.category_name}</option>)
		//     })
		//     console.log(options)
		// }
		// console.log(this.props)
		return (
			<>
				<h1>Add Forum Category</h1>
				<Form>
					<Form.Label>Forum Category Name</Form.Label>
					<Form.Control
						type='text'
						placeholder='Forum Category Name - pls do not include any special characters'
						value={this.state.Category_name}
						name='Category_name'
						onChange={this.handleChange}
					/>
					<Form.Label>Category_Description</Form.Label>
					<Form.Control
						type='text'
						placeholder='Category_Description'
						value={this.state.Category_Description}
						name='Category_Description'
						onChange={this.handleChange}
					/>
					<Form.Label>Category_Color</Form.Label>
					<Form.Control
						type='text'
						placeholder='Category_Color'
						value={this.state.Category_Color}
						name='Category_Color'
						onChange={this.handleChange}
					/>
					<br />
					<Button
						onClick={this.handleCategorySubmit}
						variant='outline-info'>
						Add Forum Category
					</Button>
				</Form>
				<hr />
				{/* <h1>Add Group</h1>
                <Form>

                    <Form.Label>1. Select Category</Form.Label>
                    <Form.Control as="select" name="fourm_cat_id" value={this.state.fourm_cat_id} onChange={this.handleChange}>
                        <option value="">Select Forum Category</option>
                        {options}
                    </Form.Control>

                    <br></br>
                    <Form.Label>2. Create a Group</Form.Label>
                    <Form.Control type="text" placeholder="add forum group" name="add_forum_group" value={this.state.add_forum_group} onChange={this.handleChange} /><br />
                    <Button onClick={this.handleSubCatSubmit} variant="outline-info">create forum group</Button>


                </Form> */}
			</>
		);
	}
}
const mapStateToProps = state => ({
	posts: state.posts
});
export default connect(mapStateToProps, {
	fetchforumcats,
	addforumcat,
	addforumgroup
})(CreateCat);