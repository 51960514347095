import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
export default function PollDisplay({ poll }) {
	let optionsDisplay = '';
	if (poll.typeOfPoll === 'choice') {
		optionsDisplay = (
			<ul>
				{poll.options.map((option, i) => {
					return (
						<li key={i}>
							<input
								name={option.optionName}
								type='radio'
								value={option.optionName}
							/>
							{option.optionName}
						</li>
					);
				})}
			</ul>
		);
	} else {
		optionsDisplay = (
			<div>
				<Button variant='contained' className='yesButton'>
					Yes
				</Button>
				<Button variant='contained' className='noButton'>
					No
				</Button>
			</div>
		);
	}
	return (
		<PollWrap img={poll.pollImg.jpeg}>
			<div class='poll'>
				<h3>{poll.question}</h3>
				{optionsDisplay}
				{poll.typeOfPoll === 'choice' && (
					<input type='submit' value='Submit' />
				)}
			</div>
		</PollWrap>
	);
}

const PollWrap = styled.div`
	.noButton {
		background-color: red;
		float: right;
		margin: auto;
	}
	.yesButton {
		color: green;
		float: left;
		margin: auto;
	}
	.poll {
		width: 300px;
		height: auto;
		min-height: 300px;
		border: #bfccd6 3px solid;
		padding: 10px;
		@include box-sizing(border-box);
		border-radius: 8px;
		background-color: #fff;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;
		background-image: ${props => `linear-gradient(
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0.9)
        ),url(${props.img})`};
		h3 {
			font-size: 1rem;
			font-family: Nunito;
			font-weight: 300;
			padding: 20px;
		}

		ul,
		li {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			padding: 10px 0 10px 0;
		}

		input[type='radio'] {
			margin: 0 10px 0 0;
		}

		input[type='submit'] {
			margin: 20px 0 0 0;
			background-color: #53626e;
			color: #fff;
			border: 0;
			width: 100%;
			height: 40px;
			font-size: 10pt;
			border-radius: 4px;
		}
	}
`;