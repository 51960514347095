import React from 'react';
import { withRouter } from 'react-router-dom';
// Import React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import Submit from '@mui/icons-material/Publish';
import Delete from '@mui/icons-material/DeleteForever';
import styled from 'styled-components';

class PostTable extends React.Component {
	state = {
		sorted: [],
		page: 0,
		pageSize: 10,
		expanded: {},
		resized: [],
		filtered: [],
		data: [],
		header: 'ALL POSTS'
	};
	updateState = () => {
		let postsArray = this.props.postsArray;
		this.setState({
			data: postsArray,
			header: 'ALL POSTS'
		});
	};
	componentDidMount() {
		let postsArray = this.props.postsArray;
		this.updateState();
	}
	componentDidUpdate(prevProps) {
		if (this.props.postsArray !== prevProps.postsArray) {
			this.updateState();
		}
	}

	renderEditable = cellInfo => {
		const sanitize = dirt => {
			if (dirt !== null) {
				const regex = /([A-Z0-9]{10})/g;
				var res = dirt.match(regex);
				if (res) {
					return res[0];
				}
			}
		};
		return (
			<div
				contentEditable
				suppressContentEditableWarning
				onBlur={e => {
					const data = [...this.state.data];
					data[cellInfo.index][cellInfo.column.id] = sanitize(
						e.target.innerHTML
					);
					this.setState({ data });
				}}
				dangerouslySetInnerHTML={{
					__html: this.state.data[cellInfo.index][cellInfo.column.id]
				}}
			/>
		);
	};
	resetState = () => {
		this.setState({
			sorted: [],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			data: this.props.postsArray,
			header: 'ALL POSTS'
		});
	};
	makeData = (data, e) => {
		this.setState({
			data: data,
			header: `${e.target.innerHTML}`
		});
	};
	convertArray = value => {
		const asinList = value.map(x => x).join(', ');
		return <span>{asinList}</span>;
	};
	render() {
		const { data } = this.state;
		const { header } = this.state;
		let postsArray = this.props.postsArray;
		let draftsArray = postsArray.filter(draft => {
			return draft.draft === true;
		});
		let publishedArray = postsArray.filter(post => {
			return post.published === true;
		});
		let waitingTBP = postsArray.filter(post => {
			return post.waitingTBP === true;
		});
		return (
			<TableWrapper>
				<div>
					<button onClick={e => this.makeData(draftsArray, e)}>
						Drafts
					</button>
					<button onClick={e => this.makeData(waitingTBP, e)}>
						Waiting for Approval
					</button>
					<button onClick={e => this.makeData(publishedArray, e)}>
						Published
					</button>
					<button onClick={this.resetState}>Reset State</button>
				</div>
        { /* ReactTable*/}
				<ReactTable
					data={data}
					noDataText='Please Click on the buttons to populate Data'
					style={{ whiteSpace: 'normal' }}
					columns={[
						{
							Header: `${header}`,
							columns: [
								{
									Header: 'TITLE',
									accessor: 'title',
									filterMethod: (filter, row) => {
										if (
											String(row[filter.id])
												.toLowerCase()
												.includes(
													filter.value.toLowerCase()
												)
										) {
											return true;
										}
									}
								},
								{
									Header: 'EXISTING ASINS',
									accessor: 'amazonProducts',
									Cell: rowInfo =>
										this.convertArray(rowInfo.value),
									filterable: false
								},
								{
									Header: 'ADD ASINS',
									accessor: 'temporary',
									Cell: this.renderEditable,
									filterable: false
								},
								{
									Header: 'SUBMIT',
									Cell: <Submit />,
									width: 80,
									style: { textAlign: 'center' },
									filterable: false
								},
								{
									Header: 'REMOVE ASINS',
									accessor: 'temporaryremoval',
									Cell: this.renderEditable,
									filterable: false
								},
								{
									Header: 'DELETE',
									Cell: <Delete />,
									width: 80,
									style: { textAlign: 'center' },
									filterable: false
								}
							]
						}
					]}
					filterable
					defaultPageSize={10}
					className='-striped -highlight'
					// Controlled props
					sorted={this.state.sorted}
					page={this.state.page}
					pageSize={this.state.pageSize}
					expanded={this.state.expanded}
					resized={this.state.resized}
					filtered={this.state.filtered}
					// Callbacks
					onSortedChange={sorted => this.setState({ sorted })}
					onPageChange={page => this.setState({ page })}
					onPageSizeChange={(pageSize, page) =>
						this.setState({ page, pageSize })
					}
					onExpandedChange={expanded => this.setState({ expanded })}
					onResizedChange={resized => this.setState({ resized })}
					onFilteredChange={filtered => this.setState({ filtered })}
					getTdProps={(state, rowInfo, column, instance) => {
						return {
							onClick: (e, handleOriginal) => {
								if (column.Header === 'SUBMIT') {
									let articleId = rowInfo.original._id;
									console.log(articleId);
									let Asins = rowInfo.original.temporary.split(
										','
									);
									console.log(Asins);
									Asins.map(asin => {
										asin = asin.toUpperCase();
										console.log(asin);
										var regex = /^B[\dA-Z]{9}|\d{9}(X|\d)$/g;
										asin = asin.match(regex);
										console.log(asin[0]);
										if (regex.test(asin) === true) {
											console.log(asin, 'success');
											this.props.addAsins(
												articleId,
												Asins
											);
											this.props.addAsinDetails(Asins);
										} else {
											toast.error(
												'please enter a valid ASIN',
												{
													containerId: 'A'
												}
											);
										}
									});
								}
								if (column.Header === 'DELETE') {
									let articleId = rowInfo.original._id;
									console.log(articleId);
									let Asins = rowInfo.original.temporaryremoval.split(
										','
									);
									console.log(this.state.data);
									console.log(Asins);
									this.props.removeAsins(articleId, Asins);
								}
								if (handleOriginal) {
									handleOriginal();
								}
							}
						};
					}}
				/>
			</TableWrapper>
		);
	}
}

export default withRouter(PostTable);

const TableWrapper = styled.div`
	.ReactTable .rt-td {
		white-space: normal;
	}
`;

/* ASIN regex

// (?:[/dp/]|$)([A-Z0-9]{10})
// */

// import React, { useState, useEffect } from 'react';
// import { useHistory,} from 'react';
// import { toast } from 'react-toastify';
// import Submit from '@mui/icons-material/Publish';
// import Delete from '@mui/icons-material/DeleteForever';
// import styled from 'styled-components';

// function PostTable(props) {
//   const [state, setState] = useState({
//     sorted: [],
//     page: 0,
//     pageSize: 10,
//     expanded: {},
//     resized: [],
//     filtered: [],
//     data: [],
//     header: 'ALL POSTS',
//   });

//   const history = useHistory();

//   const updateState = () => {
//     let postsArray = props.postsArray;
//     setState({
//       ...state,
//       data: postsArray,
//       header: 'ALL POSTS',
//     });
//   };

//   useEffect(() => {
//     let postsArray = props.postsArray;
//     updateState();
//   }, [props.postsArray]);

//   const renderEditable = (cellInfo) => {
//     const sanitize = (dirt) => {
//       if (dirt !== null) {
//         const regex = /([A-Z0-9]{10})/g;
//         var res = dirt.match(regex);
//         if (res) {
//           return res[0];
//         }
//       }
//     };

//     return (
//       <div
//         contentEditable
//         suppressContentEditableWarning
//         onBlur={(e) => {
//           const data = [...state.data];
//           data[cellInfo.index][cellInfo.column.id] = sanitize(e.target.innerHTML);
//           setState({ ...state, data });
//         }}
//         dangerouslySetInnerHTML={{
//           __html: state.data[cellInfo.index][cellInfo.column.id],
//         }}
//       />
//     );
//   };

//   const resetState = () => {
//     setState({
//       sorted: [],
//       page: 0,
//       pageSize: 10,
//       expanded: {},
//       resized: [],
//       filtered: [],
//       data: props.postsArray,
//       header: 'ALL POSTS',
//     });
//   };

//   const makeData = (data, e) => {
//     setState({
//       ...state,
//       data: data,
//       header: `${e.target.innerHTML}`,
//     });
//   };

//   const convertArray = (value) => {
//     const asinList = value.map((x) => x).join(', ');
//     return <span>{asinList}</span>;
//   };

//   const { data, header } = state;
//   let postsArray = props.postsArray;
//   let draftsArray = postsArray.filter((draft) => {
//     return draft.draft === true;
//   });
//   let publishedArray = postsArray.filter((post) => {
//     return post.published === true;
//   });
//   let waitingTBP = postsArray.filter((post) => {
//     return post.waitingTBP === true;
//   });

//   return (
//     <TableWrapper>
//       <div>
//         <button onClick={(e) => makeData(draftsArray, e)}>Drafts</button>
//         <button onClick={(e) => makeData(waitingTBP, e)}>Waiting for Approval</button>
//         <button onClick={(e) => makeData(publishedArray, e)}>Published</button>
//         <button onClick={resetState}>Reset State</button>
//       </div>
//       { /* ReactTable */}
//       <div
//         data={data}
//         noDataText='Please Click on the buttons to populate Data'
//         style={{ whiteSpace: 'normal' }}
//         columns={[
//           {
//             Header: `${header}`,
//             columns: [
//               {
//                 Header: 'TITLE',
//                 accessor: 'title',
//                 filterMethod: (filter, row) => {
//                   if (
//                     String(row[filter.id])
//                       .toLowerCase()
//                       .includes(filter.value.toLowerCase())
//                   ) {
//                     return true;
//                   }
//                 },
//               },
//               {
//                 Header: 'EXISTING ASINS',
//                 accessor: 'amazonProducts',
//                 Cell: (rowInfo) => convertArray(rowInfo.value),
//                 filterable: false,
//               },
//               {
//                 Header: 'ADD ASINS',
//                 accessor: 'temporary',
//                 Cell: renderEditable,
//                 filterable: false,
//               },
//               {
//                 Header: 'SUBMIT',
//                 Cell: <Submit />,
//                 width: 80,
//                 style: { textAlign: 'center' },
//                 filterable: false,
//               },
//               {
//                 Header: 'REMOVE ASINS',
//                 accessor: 'temporaryremoval',
//                 Cell: renderEditable,
//                 filterable: false,
//               },
//               {
//                 Header: 'DELETE',
//                 Cell: <Delete />,
//                 width: 80,
//                 style: { textAlign: 'center' },
//                 filterable: false,
//               },
//             ],
//           },
//         ]}
//         filterable
//         defaultPageSize={10}
//         className='-striped -highlight'
//         // Controlled props
//         sorted={state.sorted}
//         page={state.page}
//         pageSize={state.pageSize}
//         expanded={state.expanded}
//         resized={state.resized}
//         filtered={state.filtered}
//         // Callbacks
//         onSortedChange={(sorted) => setState({ ...state, sorted })}
//         onPageChange={(page) => setState({ ...state, page })}
//         onPageSizeChange={(pageSize, page) => setState({ ...state, page, pageSize })}
//         onExpandedChange={(expanded) => setState({ ...state, expanded })}
//         onResizedChange={(resized) => setState({ ...state, resized })}
//         onFilteredChange={(filtered) => setState({ ...state, filtered })}
//         getTdProps={(tableState, rowInfo, column, instance) => {
//           return {
//             onClick: (e, handleOriginal) => {
//               if (column.Header === 'SUBMIT') {
//                 let articleId = rowInfo.original._id;
//                 let Asins = rowInfo.original.temporary.split(',');
//                 Asins.map((asin) => {
//                   asin = asin.toUpperCase();
//                   var regex = /^B[\dA-Z]{9}|\d{9}(X|\d)$/g;
//                   asin = asin.match(regex);
//                   if (regex.test(asin) === true) {
//                     props.addAsins(articleId, Asins);
//                     props.addAsinDetails(Asins);
//                   } else {
//                     toast.error('please enter a valid ASIN', {
//                       containerId: 'A',
//                     });
//                   }
//                 });
//               }
//               if (column.Header === 'DELETE') {
//                 let articleId = rowInfo.original._id;
//                 let Asins = rowInfo.original.temporaryremoval.split(',');
//                 props.removeAsins(articleId, Asins);
//               }
//               if (handleOriginal) {
//                 handleOriginal();
//               }
//             },
//           };
//         }}
//       />
//     </TableWrapper>
//   );
// }

// export default (PostTable);

// const TableWrapper = styled.div`
//   .ReactTable .rt-td {
//     white-space: normal;
//   }
// `;
