import React from 'react';
import { useTheme, makeStyles, ThemeProvider } from '@mui/styles'; // Import ThemeProvider
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, NavLink } from 'react-router-dom';
import AdminPage from './components/AdminPage';

// const drawerWidth = 240;

// const useStyles = makeStyles(theme => ({
// 	root: {
// 		display: 'flex'
// 	},
// 	appBar: {
// 		width: `calc(100% - ${drawerWidth}px)`,
// 		marginLeft: drawerWidth
// 	},
// 	drawer: {
// 		width: drawerWidth,
// 		flexShrink: 0
// 	},
// 	drawerPaper: {
// 		width: drawerWidth
// 	},
// 	// necessary for content to be below app bar
// 	toolbar: theme.mixins.toolbar,
// 	content: {
// 		flexGrow: 1,
// 		backgroundColor: theme.palette.background.default,
// 		padding: theme.spacing(3)
// 	}
// }));

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  }
}));

export default function PermanentDrawerLeft() {
	const theme = useTheme(); // Get the theme object
	const classes = useStyles();
	let privy = localStorage.getItem('privilege')
	if (privy === 'admin') {
		return (
			<ThemeProvider theme={theme}>
			<div className={classes.root} >
				<CssBaseline />
				<AppBar position='fixed' className={classes.appBar} >
					<Toolbar style={{display:"flex",justifyContent:"center"}}>
						<Typography variant='h6' noWrap style={{textAlign:"center"}}>
							ADMIN PANEL BEST MOM
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer
					className={classes.drawer}
					variant='permanent'
					classes={{
						paper: classes.drawerPaper
					}}
					anchor='left'>
					<div className={classes.toolbar} />
					<Divider />
					<List>
						<ListItem >
							<NavLink to='/' style={{textDecoration:"none"}}>HOME</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/adminlogin' style={{textDecoration:"none"}}>LOGIN</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/users' style={{textDecoration:"none"}}>USERS</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/weekData' style={{textDecoration:"none"}}>EDIT WEEK DATA</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/create' style={{textDecoration:"none"}}>CREATE CAT & SUB-CAT</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/forum' style={{textDecoration:"none"}}>FORUM</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/articles' style={{textDecoration:"none"}}>POSTS|APPROVAL</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/urls' style={{textDecoration:"none"}}>URLS ARTICLES</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/adverts' style={{textDecoration:"none"}}>AMAZON AFFILIATION</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/shopData' style={{textDecoration:"none"}}>SHOP DATA FORMS</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/addrecipe' style={{textDecoration:"none"}}>RECIPE ADD</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/addIngredient' style={{textDecoration:"none"}}>ADD INGREDIENT</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/recipeTable' style={{textDecoration:"none"}}>EDIT RECIPE</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/contests' style={{textDecoration:"none"}}>CONTESTS</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/poll-create' style={{textDecoration:"none"}}>POLL CREATE</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/add-team' style={{textDecoration:"none"}}>ADD TEAM</NavLink>
						</ListItem>
					</List>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					<AdminPage />
				</main>
			</div>
			</ThemeProvider>
		);
	} else if (privy === 'reviewer') {
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar>
						<Typography variant='h6' noWrap>
							ADMIN PANEL BEST MOM
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer
					className={classes.drawer}
					variant='permanent'
					classes={{
						paper: classes.drawerPaper
					}}
					anchor='left'>
					<div className={classes.toolbar} />
					<Divider />
					<List>
						<ListItem>
							<NavLink to='/'>HOME</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/adminlogin'>LOGIN</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/articles'>POSTS|APPROVAL</NavLink>
						</ListItem>

					</List>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					<AdminPage />
				</main>
			</div>
		);
	} else {
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar>
						<Typography variant='h6' noWrap>
							ADMIN PANEL BEST MOM
							</Typography>
					</Toolbar>
				</AppBar>
				<Drawer
					className={classes.drawer}
					variant='permanent'
					classes={{
						paper: classes.drawerPaper
					}}
					anchor='left'>
					<div className={classes.toolbar} />
					<Divider />
					<List>
						<ListItem>
							<NavLink to='/'>HOME</NavLink>
						</ListItem>
						<ListItem>
							<NavLink to='/adminlogin'>LOGIN</NavLink>
						</ListItem>
					</List>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					<AdminPage />
				</main>
			</div>
		)

	}

}