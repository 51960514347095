import {
    FETCH_FORUM_CATS,
    ADD_FORUM_CATS,
    ADD_FORUM_GROUPS
} from './types';
import axios from 'axios';


export const fetchforumcats = () => {
    return function (dispatch) {
        axios
            .get(`https://api.bestmom.org/forum`)
            .then(res => {
                dispatch({
                    type: FETCH_FORUM_CATS,
                    payload: res.data
                });
            });
    };
};


export const addforumcat = (data) => {
    return function (dispatch) {
        axios({
            method: 'post',
            url: `https://api.bestmom.org/forum/addCat`,
            data: {
                Category_name: data.Category_name,
                Category_Color: data.Category_Color,
                Category_Description: data.Category_Description
            }
        })
            .then(res => {
                dispatch({
                    type: ADD_FORUM_CATS,
                    payload: res.data
                });
            }).catch((err) => {
                console.log(err)
            })
    };
};

export const addforumgroup = (sub_category_name, category_name) => {
    return function (dispatch) {
        axios({
            method: 'post',
            url: `https://api.bestmom.org/admin/addsubcategory`,
            data: {
                sub_category_name: sub_category_name,
                category_name: category_name
            }
        })
            .then(res => {
                dispatch({
                    type: ADD_FORUM_GROUPS,
                    payload: res.data
                });
            }).catch((err) => {
                console.log(err)
            })
    };
};