import { FETCH_INGREDIENTS, FETCH_RECIPES, CLEAR_RECIPES, DELETE_RECIPE } from './types';
import axios from 'axios';

export const fetchIngredients = () => {
	return function(dispatch) {
		axios.get('https://api.bestmom.org/article/getIngredients').then(res => {
			dispatch({
				type: FETCH_INGREDIENTS,
				payload: res.data
			});
		});
	};
};

export const fetchRecipes = () => {
	return function(dispatch) {
		axios.get('https://api.bestmom.org/article/recipes').then(res => {
			dispatch({
				type: FETCH_RECIPES,
				payload: res.data
			});
		});
	};
};
export const deleteRecipe = titleUrl => {
	return function(dispatch) {
		axios.get(`https://api.bestmom.org/article/recipes/delete?titleUrl=${titleUrl}`).then(res => {
			dispatch({
				type: DELETE_RECIPE,
				payload: res.data
			});
		});
	};
};

export const clearRecipes = () => {
	return function(dispatch) {
		dispatch({
			type: CLEAR_RECIPES
		});
	};
};