import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
// import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
// import ExpansionPanel from '@mui/material/ExpansionPanel';
// import ExpansionPanelSummary from '@mui/material/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@mui/material/ExpansionPanelDetails';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {  toast } from 'react-toastify';
import { connect } from 'react-redux';
import axios from 'axios';
import { addShopProduct } from '../redux/actions/shopActions';
import Modal from '@mui/material/Modal';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';

class ShopData extends Component {
	state = {
		title: '',
		titleImgUrl: '',
		titleImg: '',
		productName: '',
		description: '',
		intro: '',
		availableBrands: [],
		brandImgPh: '',
		brandNamePh: '',
		minProductPrice: '',
		maxProductPrice: '',
		maxScale: '',
		pricingPer: '',
		buyingFactorNames: [],
		bFPlaceholder: '',
		types: [],
		typeObject: {
			typeName: '',
			uniqueFeatures: '',
			typeImgUrl: '',
			typeDesc: '',
			TypeProducts: []
		},
		productObject: {
			asin: '',
			prodName: '',
			pricing: '',
			brandName: '',
			// rating: '',
			// uniques: '',
			buyingFactors: []
		},
		bfObject: {
			factorName: '',
			factorVal: ''
		},
		bfObjectPh: {
			factorName: '',
			factorType: '',
			factorVal: '',
			ratingIcon: ''
		},
		modalOpen: false
	};
	componentDidMount() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
		} else {
			this.props.history.push('/adminlogin');
		}
	}
	handleOpen = () => {
		this.setState({
			modalOpen: true
		});
	};

	handleClose = () => {
		this.setState({
			modalOpen: false
		});
	};
	addProductsToType = () => {
		console.log('adding product to Type');
		if (this.state.productObject.asin) {
			let val = [...this.state.typeObject.TypeProducts];
			const prod = this.state.productObject;
			if (prod.asin) {
				val.push(prod);
			}
			console.log(val);
			this.setState(state => {
				state['typeObject']['TypeProducts'] = val;
			});
			this.resetProductObject();
		} else {
			toast.error('cannot be added with out asin');
		}
	};
	resetProductObject = () => {
		this.setState({
			productObject: {
				asin: '',
				prodName: '',
				pricing: '',
				brandName: '',
				buyingFactors: []
			}
		});
	};
	resetType = () => {
		this.setState({
			typeObject: {
				typeName: '',
				uniqueFeatures: [],
				typeImgUrl: '',
				typeDesc: '',
				TypeProducts: []
			},
			productObject: {
				asin: '',
				prodName: '',
				pricing: '',
				brandName: '',
				buyingFactors: []
			}
		});
	};
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	handleSubChange = (cat, key) => e => {
		const val = e.target.value;
		const category = { ...this.state[cat] };
		category[key] = val;
		this.setState({ [cat]: category });
	};
	resetbfObjectPh = () => {
		this.setState({
			bfObjectPh: {
				factorName: '',
				factorType: '',
				factorVal: '',
				ratingIcon: ''
			}
		});
	};
	addBF = () => {
		let obj = {
			factorName: this.state.bfObjectPh.factorName,
			factorType: this.state.bfObjectPh.factorType,
			ratingIcon: this.state.bfObjectPh.ratingIcon
		};
		this.setState({
			buyingFactorNames: [...this.state.buyingFactorNames, obj],
			modalOpen: false
		});
		this.resetbfObjectPh();
	};
	addBFactorToProd = () => {
		let factors = [];
		this.state.buyingFactorNames.map((bf, i) => {
			let obj = {};
			let factorValId = `factorVal-${i}`;
			obj.factorName = bf.factorName;
			obj.factorType = bf.factorType;
			obj.ratingIcon = bf.ratingIcon;
			obj.factorVal = this.state[factorValId];
			factors.push(obj);
		});
		console.log(factors);
		this.setState({
			productObject: {
				...this.state.productObject,
				buyingFactors: factors
			}
		});
		toast.success('All the Factor Values are Saved');
	};

	addType = () => {
		if (this.state.typeObject.typeName !== '') {
			if (this.state.typeObject.TypeProducts.length > 0) {
				this.setState({
					types: [...this.state.types, this.state.typeObject]
				});
				this.resetType();
				toast.success('Submitted', {
					containerId: 'A'
				});
			} else {
				toast.error(
					'Atleast 1 product needs to be addded in each Type'
				);
			}
		} else {
			toast.error('Cannot be submitted : Empty Fields', {
				containerId: 'A'
			});
		}
	};
	addBrand = () => {
		if (this.state.brandImgPh && this.state.brandNamePh) {
			let obj = {
				brandName: this.state.brandNamePh,
				brandImgUrl: ''
			};
			axios({
				method: 'post',
				url: `https://api.bestmom.org/upload/byUrl`,
				data: {
					Url: this.state.brandImgPh
				}
			})
				.then(res => {
					obj.brandImgUrl = res.data.file.url;
					toast.dismiss();
					toast.success('Success!', {
						position: toast.POSITION.TOP_CENTER
					});
					this.setState({
						availableBrands: [...this.state.availableBrands, obj],
						brandImgPh: '',
						brandNamePh: ''
					});
				})
				.catch(function(error) {
					console.log(error);
					toast(
						'save unsuccessful please save the data externally and contact Admin!',
						{ containerId: 'A' }
					);
				});
		} else {
			toast.error('Cannot be submitted : Empty Fields', {
				containerId: 'A'
			});
		}
	};
	changeUrl = inputHint => {
		let validUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
		let changedUrl = '';
		if (inputHint === 'typeObject') {
			let val = this.state.typeObject.typeImgUrl;
			let result = validUrl.test(val);
			if (result) {
				if (this.state.typeObject.typeName !== '') {
					axios({
						method: 'post',
						url: `https://api.bestmom.org/upload/byUrl`,
						data: {
							Url: this.state.typeObject.typeImgUrl,
							fileName: this.state.typeObject.typeName
						}
					})
						.then(res => {
							changedUrl = res.data.file.url;
							console.log(changedUrl);
							toast.dismiss();
							toast.success('Success!');
							setTimeout(() => {
								console.log('this is working');
								this.setState(state => {
									state[inputHint]['typeImgUrl'] = changedUrl;
								});
								console.log(this.state);
							}, 1000);
						})
						.catch(function(error) {
							console.log(error);
							toast(
								'save unsuccessful please save the data externally and contact Admin!'
							);
						});
				} else {
					toast.error('please enter the Type Name before submitting');
				}
			} else {
				toast.error('cannot proceed with Empty Fields');
			}
		} else if (inputHint === 'titleImgUrl') {
			let val = this.state.titleImg;
			let result = validUrl.test(val);
			// console.log(result)
			if (result) {
				if (this.state.productName !== '') {
					axios({
						method: 'post',
						url: `https://api.bestmom.org/upload/byUrl`,
						data: {
							Url: this.state.titleImg
						}
					})
						.then(res => {
							changedUrl = res.data.file.url;
							console.log(changedUrl);
							toast.dismiss();
							setTimeout(() => {
								console.log('this is working');
								this.setState({
									titleImgUrl: changedUrl
								});
								console.log(this.state);
								toast.success('Success!');
							}, 500);
						})
						.catch(function(error) {
							console.log(error);
							toast(
								'save unsuccessful please save the data externally and contact Admin!'
							);
						});
				} else {
					toast.info('Please fill Product Name first and Re-submit');
				}
			} else {
				toast.error('cannot submit empty fields');
			}
		}
	};

	sendData = () => {
		if (this.state.types.length > 0) {
			let Data = {
				title: this.state.title,
				productName: this.state.productName,
				titleImgUrl: this.state.titleImgUrl,
				description: this.state.description,
				minProductPrice: this.state.minProductPrice,
				maxProductPrice: this.state.maxProductPrice,
				maxScale: this.state.maxScale,
				intro: this.state.intro,
				types: this.state.types,
				availableBrands: this.state.availableBrands,
				buyingFactorNames: this.state.buyingFactorNames
			};
			this.props.addShopProduct(Data);
		} else {
			toast.error('Ateast 1 type is required');
		}
	};
	render() {
		let bfObjectDisplay = this.state.buyingFactorNames.map(
			(eachFactor, i) => {
				let factorNameId = `factorName-${i}`;
				let factorValId = `factorVal-${i}`;

				switch (eachFactor.factorType) {
					case 'rating':
						return (
							<FormGroup>
								<TextField
									disabled
									id='demo-simple-select'
									value={eachFactor.factorName}
								/>
								<TextField
									id='standard-number'
									fullWidth
									type='number'
									label='Rate on a scale of 5'
									value={this.state.factorValId}
									name={factorValId}
									onChange={this.handleChange}
								/>
							</FormGroup>
						);
					case 'bool':
						return (
							<FormGroup>
								<TextField
									disabled
									id='demo-simple-select'
									value={eachFactor.factorName}></TextField>
								<Select
									id='demo-simple-select'
									value={this.state.factorValId}
									name={factorValId}
									onChange={this.handleChange}>
									<MenuItem value='true'>Yes</MenuItem>
									<MenuItem value='false'>No</MenuItem>
									<MenuItem value='NA'>NA</MenuItem>
								</Select>
							</FormGroup>
						);
					case 'text':
						return (
							<FormGroup>
								<TextField
									disabled
									id='demo-simple-select'
									value={eachFactor.factorName}></TextField>
								<TextField
									id='standard-textarea'
									fullWidth
									multiline
									label='text value for factor'
									value={this.state.factorValId}
									name={factorValId}
									onChange={this.handleChange}
								/>
							</FormGroup>
						);
				}
			}
		);
		return (
			<ShopFormWrapper>
				<section>
					<TextField
						id='standard-basic'
						label='Title - eg : BEST * DIAPERS IN INDIA'
						fullWidth
						value={this.state.title}
						onChange={this.handleChange}
						name='title'
					/>
					<TextField
						id='standard-basic'
						label='Product Class eg : DIAPERS'
						fullWidth
						value={this.state.productName}
						onChange={this.handleChange}
						name='productName'
					/>
					<TextField
						id='standard-basic'
						label='Image Url for Title'
						fullWidth
						value={this.state.titleImg}
						onChange={this.handleChange}
						name='titleImg'></TextField>
					<Button onClick={() => this.changeUrl('titleImgUrl')}>
						<AddIcon />
						SUBMIT
					</Button>
					<TextField
						id='standard-basic'
						label='Short Description no more than 10 words'
						fullWidth
						value={this.state.description}
						onChange={this.handleChange}
						name='description'
					/>
					<TextField
						id='standard-basic'
						multiline
						rows='4'
						label='Intro for product Category not less than 50 words'
						fullWidth
						value={this.state.intro}
						onChange={this.handleChange}
						name='intro'
					/>
					<TextField
						style={{ margin: '10px' }}
						id='standard-basic'
						label='min Product Price in ₹'
						value={this.state.minProductPrice}
						onChange={this.handleChange}
						name='minProductPrice'
					/>
					<TextField
						style={{ margin: '10px' }}
						id='standard-basic'
						label='max Product Price in ₹'
						value={this.state.maxProductPrice}
						onChange={this.handleChange}
						name='maxProductPrice'
					/>
					<TextField
						id='standard-basic'
						fullWidth
						label='max scale for price range eg: if max product price is 15 rupees enter 100rs as a scale max'
						value={this.state.maxScale}
						onChange={this.handleChange}
						name='maxScale'
					/>
					<TextField
						id='standard-basic'
						fullWidth
						label="common scale for price comparision i.e per 10 gm or 'x' ml "
						value={this.state.pricingPer}
						onChange={this.handleChange}
						name='pricingPer'
					/>
					<div>
						<h6 style={{ marginTop: '20px' }}>
							Brands Available for {this.state.productName}:
						</h6>
						<ol>
							{this.state.availableBrands.map((brands, i) => {
								return <li key={i}>{brands.brandName}</li>;
							})}
						</ol>
					</div>

					<Paper component='form' className='availableBrands'>
						<InputBase
							className='brandName'
							placeholder='Brand Name'
							value={this.state.brandNamePh}
							onChange={this.handleChange}
							name='brandNamePh'
						/>
						<InputBase
							className='brandImagesAdd'
							placeholder='Brand Image link'
							value={this.state.brandImgPh}
							onChange={this.handleChange}
							name='brandImgPh'
							style={{ padding: '10px' }}
						/>
						<IconButton
							style={{ float: 'right' }}
							onClick={this.addBrand}
							aria-label='search'>
							<AddIcon />
						</IconButton>
					</Paper>
					<div>
						<h6 style={{ marginTop: '20px' }}>
							Buying Factors {this.state.productName}:
						</h6>
						<p>Added : {this.state.buyingFactorNames.length}</p>
						<ol>
							{this.state.buyingFactorNames.map((bf, i) => {
								return <li key={i}>{bf.factorName}</li>;
							})}
						</ol>
					</div>
					<Button
						variant='contained'
						color='primary'
						onClick={this.handleOpen}>
						ADD Buying Factor
					</Button>
					<Modal
						aria-labelledby='simple-modal-title'
						aria-describedby='simple-modal-description'
						open={this.state.modalOpen}
						onClose={this.handleClose}>
						<Paper
							style={{
								position: 'absolute',
								width: '80vw',
								top: '30vh',
								left: '10vw'
							}}>
							<h4 style={{ textAlign: 'center' }}>
								Buying Factor Details
							</h4>
							<FormHelperText style={{ marginBottom: '10px' }}>
								Buying Factor Name
							</FormHelperText>
							<TextField
								id='standard-basic'
								fullWidth
								value={this.state.bfObjectPh.factorName}
								onChange={this.handleSubChange(
									'bfObjectPh',
									'factorName'
								)}
							/>
							<FormHelperText style={{ marginBottom: '10px' }}>
								Buying Factor Type
							</FormHelperText>
							<Select
								id='demo-simple-select'
								label='factor type'
								fullWidth
								value={this.state.bfObjectPh.factorType}
								onChange={this.handleSubChange(
									'bfObjectPh',
									'factorType'
								)}>
								<MenuItem value={'rating'}>
									Rating on Scale of 5
								</MenuItem>
								<MenuItem value={'bool'}>Yes/No</MenuItem>
								<MenuItem value={'text'}>text</MenuItem>
							</Select>
							{this.state.bfObjectPh.factorType === 'rating' && (
								<>
									<FormHelperText
										style={{ marginBottom: '10px' }}>
										Rating Icon
									</FormHelperText>
									<Select
										id='demo-simple-select'
										label='factor rating icon'
										fullWidth
										value={this.state.bfObjectPh.ratingIcon}
										onChange={this.handleSubChange(
											'bfObjectPh',
											'ratingIcon'
										)}>
										<MenuItem value={'Heart'}>
											Hearts
										</MenuItem>
										<MenuItem value={'Star'}>
											Stars
										</MenuItem>
										<MenuItem value={'ThumbsUp'}>
											Thumbs Up
										</MenuItem>
									</Select>
								</>
							)}

							<IconButton
								style={{ float: 'right' }}
								onClick={this.addBF}
								aria-label='search'>
								SUBMIT{' '}
								<SendIcon
									style={{ color: 'red', marginLeft: '30px' }}
								/>
							</IconButton>
						</Paper>
					</Modal>
					<hr />
				</section>
				<section>
					<h5>TYPES / VARIETIES</h5>
					<div>
						<p>Submitted Types : {this.state.types.length}</p>
						{this.state.types.map(type => {
							return <div>{type.typeName}</div>;
						})}
					</div>
					<hr />
					<h6 style={{ textAlign: 'center' }}>
						ADD TYPE / VARIETY TO THIS PRODUCT CATEGORY
					</h6>
					<Paper style={{ padding: '10px' }}>
						<FormGroup>
							<TextField
								id='standard-basic'
								label='Type Name'
								value={this.state.typeObject.typeName}
								onChange={this.handleSubChange(
									'typeObject',
									'typeName'
								)}
							/>

							<TextField
								id='standard-basic'
								label='Type Description'
								value={this.state.typeObject.typeDesc}
								onChange={this.handleSubChange(
									'typeObject',
									'typeDesc'
								)}
							/>

							<TextField
								id='standard-basic'
								label='Unique Features - use comma to seperate features'
								value={this.state.typeObject.uniqueFeatures}
								onChange={this.handleSubChange(
									'typeObject',
									'uniqueFeatures'
								)}
							/>

							<TextField
								placeholder='Image Url for Type/Variant'
								style={{ marginTop: '10px' }}
								fullWidth
								value={this.state.typeObject.typeImgUrl}
								onChange={this.handleSubChange(
									'typeObject',
									'typeImgUrl'
								)}
							/>
							<Button
								style={{ width: 'fit' }}
								onClick={() => this.changeUrl('typeObject')}>
								<AddIcon />
								SUBMIT
							</Button>

							<p
								style={{
									margin: '20px',
									textAlign: 'center',
									color: 'blue'
								}}>
								ADD A PRODUCT TO THIS TYPE{' '}
								{this.state.typeObject.typeName}
							</p>

							<div>
								<TextField
									id='standard-basic'
									label='Product ASIN'
									fullWidth
									value={this.state.productObject.asin}
									onChange={this.handleSubChange(
										'productObject',
										'asin'
									)}
								/>
								<TextField
									id='standard-basic'
									label='Product Name'
									fullWidth
									value={this.state.productObject.prodName}
									onChange={this.handleSubChange(
										'productObject',
										'prodName'
									)}
								/>
								<TextField
									id='standard-basic'
									label='Price per Common Scale'
									fullWidth
									value={this.state.productObject.pricing}
									onChange={this.handleSubChange(
										'productObject',
										'pricing'
									)}
								/>
								<TextField
									id='standard-basic'
									label='Brand of the Product'
									fullWidth
									value={this.state.productObject.brandName}
									onChange={this.handleSubChange(
										'productObject',
										'brandName'
									)}
								/>
							</div>

							<Grid
								container
								direction='row'
								justify='center'
								alignItems='center'>
								<Grid item sm={12} md={8} className='bfitem'>
									{bfObjectDisplay}
									{this.state.buyingFactorNames.length >
										0 && (
										<Button
											variant='contained'
											onClick={this.addBFactorToProd}
											color='secondary'>
											Save the Factor
										</Button>
									)}
								</Grid>
							</Grid>

							<Button
								style={{ margin: '10px' }}
								variant='contained'
								onClick={this.addProductsToType}
								color='secondary'>
								Add Products to Type
							</Button>
							<Button
								style={{ margin: '10px' }}
								variant='contained'
								onClick={this.addType}
								color='primary'>
								Submit Type / Variant
							</Button>
						</FormGroup>
					</Paper>
					<Button
						style={{ margin: '10px' }}
						variant='contained'
						onClick={this.sendData}
						color='primary'>
						Finish
					</Button>
				</section>
			</ShopFormWrapper>
		);
	}
}

const mapStateToProps = state => ({
	posts: state.posts.posts
});
export default connect(mapStateToProps, { addShopProduct })(ShopData);

const ShopFormWrapper = styled.div`
	padding: 15px;
	.MuiExpansionPanelDetails-root {
		display: block;
	}
	.buyingFactorAdd {
		padding: 5px;
		width: 90%;
	}

	.bfitem {
		padding: 5px;
		margin-top: 20px;
	}
`;