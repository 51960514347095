import React, { PureComponent } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Quote from '@editorjs/quote';
import LinkTool from '@editorjs/link';
import Warning from '@editorjs/warning';
import Embed from '@editorjs/embed';
import Checklist from '@editorjs/checklist';
import Table from '@editorjs/table';
import axios from 'axios';
import {  toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

export default class Editor extends PureComponent {
	state = {
		editor: null,
		data: '',
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			this.setState({
				data: this.props.data,
			});
		}
	}

	saveToLocal = () => {
		this.editor.save().then(async (savedData) => {
			this.setState({
				data: savedData,
			});
			this.props.changeFunction(this.state.data);
			//TODO : if article-id is available the url has to be changed with the article-id and data can be sent either through the local storage or through props
		});
	};

	async componentDidMount() {
		if (this.props.data === undefined) {
		} else {
			await this.setState({
				data: this.props.data,
			});
		}
		this.editor = new EditorJS({
			holder: 'codex-editor',
			tools: {
				header: {
					class: Header,
					inlineToolbar: true,
					config: {
						placeholder: 'Title',
					},
				},
				image: {
					class: ImageTool,
					inlineToolbar: true,
					config: {
						endpoints: {
							byFile: '/upload',
							// byUrl: '/upload/byUrl',
						},
					},
				},
				quote: {
					class: Quote,
					inlineToolbar: true,
					shortcut: 'CMD+SHIFT+O',
					config: {
						quotePlaceholder: 'Enter a quote',
						captionPlaceholder: "Quote's author",
					},
				},
				linkTool: {
					class: LinkTool,
					inlineToolbar: true,
					config: {
						endpoint: '/editorjsapi/linkfetch',
					},
				},
				list: {
					class: List,
					inlineToolbar: true,
				},
				warning: {
					class: Warning,
					inlineToolbar: true,
					shortcut: 'CMD+SHIFT+W',
					config: {
						titlePlaceholder:
							'AVOID/MUST/DO NOT/CAUTION/SUGGESTION',
						messagePlaceholder: 'Message',
					},
				},
				embed: {
					class: Embed,
					inlineToolbar: true,
					config: {
						services: {
							youtube: true,
							coub: true,
						},
					},
				},
				checklist: {
					class: Checklist,
					inlineToolbar: true,
				},
				table: {
					class: Table,
					inlineToolbar: true,
				},
			},
			data: {
				blocks: this.state.data,
			},
			onReady: function () {},
			onChange: this.saveToLocal,
		});
	}
	componentWillUnmount() {
		this.setState({
			data: '',
			editor: null,
		});
	}
	render() {
		return (
			<>
				<div className='edit-area' id='codex-editor' />
				<Button
					variant='contained'
					color='primary'
					type='submit'
					onClick={() => {
						this.props.changeFunction(this.state.data);
						this.props.tabIndex();
					}}>
					SaveData
				</Button>
			</>
		);
	}
}