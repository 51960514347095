import React, { Component } from 'react';
import PostTable from '../UIComps/PostTable';
import { connect } from 'react-redux';
import {
	fetchAllPosts,
	fetchCats,
	clearPostsArray,
	deleteArticle,
	fetchReviewed
} from '../redux/actions/PostActions';

class Articles extends Component {
	componentDidMount() {
		let privy = localStorage.getItem('privilege')
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user !== null || undefined) {
			if (privy === 'admin') {
				this.props.fetchAllPosts();
			} else if (privy === 'reviewer') {
				// console.log('fetching only reviewed Articles')
				this.props.fetchReviewed();
			}
		} else {
			this.props.history.push('/adminlogin');
		}
	}
	render() {
		//console.log(this.props.posts)
		let privy = localStorage.getItem('privilege')
		return (
			<div>
				<PostTable
					clearPostsArray={this.props.clearPostsArray}
					postsArray={this.props.posts}
					deleteArticle={this.props.deleteArticle}
					privy={privy}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	posts: state.posts.posts,
});
export default connect(mapStateToProps, {
	fetchAllPosts,
	fetchCats,
	clearPostsArray,
	deleteArticle,
	fetchReviewed
})(Articles);