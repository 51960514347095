import React from "react";
import { withRouter } from "react-router-dom";
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import styled from 'styled-components'


class PostTable extends React.Component {
  state = {
    sorted: [],
    page: 0,
    pageSize: 50,
    expanded: {},
    resized: [],
    filtered: [],
    data: [],
    header: 'ALL POSTS'
  }
  componentDidMount() {
    let postsArray = this.props.postsArray
    this.setState({
      data: postsArray,
      header: 'ALL POSTS'
    })
  }
  resetState = () => {
    this.setState({
      sorted: [],
      page: 0,
      pageSize: 50,
      expanded: {},
      resized: [],
      filtered: [],
      data: [],
      header: 'ALL POSTS'
    });
  }
  makeData = (data, e) => {
    this.setState({
      data: data,
      header: `${e.target.innerHTML}`
    })
  }

  render() {
    const { header } = this.state;
    let postsArray = this.props.postsArray
    return (
      <TableWrapper>
    {   /* ReactTable*/}
        <ReactTable
          data={postsArray}
          noDataText="Please Click on the buttons to populate Data"
          style={{ whiteSpace: 'normal', fontSize: '12px', fontFamily: 'Nunito' }}
          columns={[
            {
              Header: `${header}`,
              columns: [
                {
                  id: 'full_url',
                  Header: "URL",
                  accessor: d => `https://bestmom.org/pregnancy/${d.titleUrl}`
                },
                {
                  Header: "Title",
                  accessor: "title",
                  filterMethod: (filter, row) => {
                    if (String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())) { return true; }
                  },
                },
                {
                  id: 'sub_category_name',
                  Header: "SUB-CAT",
                  accessor: d => d.subCategory.sub_category_name
                },
              ]
            }
          ]}
          filterable
          defaultPageSize={50}
          className="-striped -highlight"
          // Controlled props
          sorted={this.state.sorted}
          page={this.state.page}
          pageSize={this.state.pageSize}
          expanded={this.state.expanded}
          resized={this.state.resized}
          filtered={this.state.filtered}
          // Callbacks
          onSortedChange={sorted => this.setState({ sorted })}
          onPageChange={page => this.setState({ page })}
          onPageSizeChange={(pageSize, page) =>
            this.setState({ page, pageSize })}
          onExpandedChange={expanded => this.setState({ expanded })}
          onResizedChange={resized => this.setState({ resized })}
          onFilteredChange={filtered => this.setState({ filtered })}

          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header === 'TITLE') {
                  this.props.history.push({
                    pathname: `/article/${rowInfo.original._id}`,
                    state: rowInfo.original
                  })
                }
                if (column.Header === 'AUTHOR') {
                  console.log(rowInfo.original.author._id)
                }
                console.log('It was in this table instance:', instance)
                if (handleOriginal) {
                  handleOriginal()
                }
              }
            }
          }}
        />

      </TableWrapper>
    );
  }
}

export default withRouter(PostTable);


const TableWrapper = styled.div`
.ReactTable .rt-td {
    white-space: normal;
}
`