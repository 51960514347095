// import React, { PureComponent } from 'react';
// import Users from './Users';
// import Login from './Login';
// import Articles from './Articles';
// import Recipes from './Recipes';
// import Home from './Home';
// import CreateCat from './CreateCat/CreateCat';
// import CreateForum from './Forum/CreateForum';
// import PostApprovalPage from './PostApprovalPage';
// import EditRecipe from './EditRecipe';
// import AmazonAffiliation from './AmazonAffiliation';
// import ArticleDetails from './ArticleDetails';
// import RecipeAdd from './RecipeAdd';
// import Contests from './Contests';
// import PollCreate from './PollCreate';
// import TeamMember from './TeamMember';
// import IngredientAdd from './IngredientAdd';
// import WeekByWeekIndex from './WeekByWeek/index';
// import WeekByWeek from './WeekByWeek/WeekByWeek';
// import ShopData from './ShopData';
// import { BrowserRouter as Router, Route, } from 'react-router-dom';
// import { Link, Redirect } from 'react-router-dom';

// export default class AdminPage extends PureComponent {
// 	render() {
// 		return (
//             <Router>
            
//             <Route path="/users" element={<PrivateRoute element={<Users />} />} />
//             <Route path="/" element={<Home />} />
//             <Route path="/adminlogin" element={<Login />} />
//             <Route path="/articles" element={<PrivateRoute element={<Articles />} />} />
//             <Route path="/articles/:id" element={<PrivateRoute element={<PostApprovalPage />} />} />
//             <Route path="/create" element={<PrivateRoute element={<CreateCat />} />} />
//             <Route path="/forum" element={<PrivateRoute element={<CreateForum />} />} />
//             <Route path="/adverts" element={<PrivateRoute element={<AmazonAffiliation />} />} />
//             <Route path="/shopData" element={<PrivateRoute element={<ShopData />} />} />
//             <Route path="/urls" element={<PrivateRoute element={<ArticleDetails />} />} />
//             <Route path="/addrecipe" element={<PrivateRoute element={<RecipeAdd />} />} />
//             <Route path="/addIngredient" element={<PrivateRoute element={<IngredientAdd />} />} />
//             <Route path="/recipeTable" element={<PrivateRoute element={<Recipes />} />} />
//             <Route path="/editRecipe/:id" element={<PrivateRoute element={<EditRecipe />} />} />
//             <Route path="/contests" element={<PrivateRoute element={<Contests />} />} />
//             <Route path="/poll-create" element={<PrivateRoute element={<PollCreate />} />} />
//             <Route path="/add-team" element={<PrivateRoute element={<TeamMember />} />} />
//             <Route path="/weekData" element={<PrivateRoute element={<WeekByWeekIndex />} />} />
//             <Route path="/weekData/:week_number" element={<PrivateRoute element={<WeekByWeek />} />} />
           
//           </Router>
// 		);
// 	}
// }

// // export const PrivateRoute = ({ component: Component, ...rest }) => {
// // 	let privy = localStorage.getItem('privilege')
// // 	return (
// // 		<Route
// // 			{...rest}
// // 			render={props =>
// // 				(privy === 'admin' || 'reviewer') ? (
// // 					<Component {...props} />
// // 				) : (
// // 						<Redirect
// // 							to={{
// // 								pathname: '/adminlogin',
// // 								state: { from: props.location }
// // 							}}
// // 						/>
// // 					)
// // 			}
// // 		/>)
// // };

// export const PrivateRoute = ({ element }) => {
//     let privy = localStorage.getItem('privilege');
//     return privy === 'admin' || privy === 'reviewer' ? (
//       element
//     ) : (
//       <Redirect to="/adminlogin" replace state={{ from: window.location.pathname }} />
//     );
//   };

import React, { PureComponent } from 'react';
import Users from './Users';
import Login from './Login';
import Articles from './Articles';
import Recipes from './Recipes';
import Home from './Home';
import CreateCat from './CreateCat/CreateCat';
import CreateForum from './Forum/CreateForum';
import PostApprovalPage from './PostApprovalPage';
import EditRecipe from './EditRecipe';
import AmazonAffiliation from './AmazonAffiliation';
import ArticleDetails from './ArticleDetails';
import RecipeAdd from './RecipeAdd';
import Contests from './Contests';
import PollCreate from './PollCreate';
import TeamMember from './TeamMember';
import IngredientAdd from './IngredientAdd';
import WeekByWeekIndex from './WeekByWeek/index';
import WeekByWeek from './WeekByWeek/WeekByWeek';
import ShopData from './ShopData';
import {  Routes, Route,Switch ,Outlet } from 'react-router-dom';
import { Link, Redirect } from 'react-router-dom';

export default class AdminPage extends PureComponent {
	render() {
		return (
     <Switch>
      <PrivateRoute exact path='/users' component={Users} />
      <Route exact path='/' component={Home} />
      <Route exact path='/adminlogin' component={Login} />
      <PrivateRoute path='/articles' exact component={Articles} />
      <PrivateRoute path='/articles/:id' component={PostApprovalPage} />
      <PrivateRoute path='/create' exact component={CreateCat} />
      <PrivateRoute path='/forum' exact component={CreateForum} />
      <PrivateRoute path='/adverts' exact component={AmazonAffiliation} />
      <PrivateRoute path='/shopData' exact component={ShopData} />
      <PrivateRoute path='/urls' exact component={ArticleDetails} />
      <PrivateRoute path='/addrecipe' exact component={RecipeAdd} />
      <PrivateRoute path='/addIngredient' exact component={IngredientAdd} />
      <PrivateRoute path='/recipeTable' exact component={Recipes} />
      <PrivateRoute path='/editRecipe/:id' exact component={EditRecipe} />
      <PrivateRoute path='/contests' exact component={Contests} />
      <PrivateRoute path='/poll-create' exact component={PollCreate} />
      <PrivateRoute path='/add-team' exact component={TeamMember} />
      <PrivateRoute path='/weekData' exact component={WeekByWeekIndex} />
      <PrivateRoute
        path='/weekData/:week_number'
        exact
        component={WeekByWeek}
      />
      </Switch>
		);
	}
}

export const PrivateRoute = ({ component: Component, ...rest }) => {
	let privy = localStorage.getItem('privilege')
	return (
		<Route
			{...rest}
			render={props =>
				(privy === 'admin' || 'reviewer') ? (
					<Component {...props} />
				) : (
						<Redirect
							to={{
								pathname: '/adminlogin',
								state: { from: props.location }
							}}
						/>
					)
			}
		/>)
};