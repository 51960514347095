import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { fetchIngredients } from '../redux/actions/RecipeActions';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

class EditRecipe extends Component {
	state = {
		recipeTitle: '',
		author: {},
		titleUrl: '',
		seoDesc: '',
		recipeDesc: '',
		cuisine: '',
		imageUrl: '',
		imageWebpUrl: '',
		category: '',
		dishType: '',
		cookTime: 0,
		prepTime: 0,
		servSize: 0,
		steps: [],
		views: 0,
		likes: 0,
		notes: '',
		Ingredients: [],
		showIngredientsForm: false,
		editIngredientsForm: false,
		stepContent: '',
		dishCalories: '',
		recipeNutrition: null,
		id: '',
		bmrecipe: true
	};
	handleAuthor = () => {
		if (!this.state.bmrecipe) {
			this.setState({
				bmrecipe: true,
				author: {}
			});
		} else {
			this.setState({
				bmrecipe: false,
				author: {
					name: 'Food Memo',
					logo: '/upload/foodmemo.jpeg',
					instaLink: 'https://www.instagram.com/thefoodmemo/',
					description:
						'The Food Memo is a food recipe and information blog that has turned into a cloud bakery on the request of our beloved followers.'
				}
			});
		}
	};
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	deleteStep = i => {
		let newSteps = this.state.steps.filter(step => {
			return this.state.steps.indexOf(step) !== i;
		});
		this.setState({
			steps: newSteps
		});
	};
	onChangeImage = e => {
		// this.setState({ uploading: true })
		let uploadedFile = e.target.files[0];
		// const formData = new FormData()
		const formData = new FormData();
		formData.append('image', uploadedFile);
		axios
			.post('https://api.bestmom.org/upload/recipeImages', formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then(response => {
				console.log(response);
				this.setState({
					imageUrl: response.data.file.url,
					imageWebpUrl: response.data.file.webpUrl
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	addIngredients = () => {
		this.setState({
			showIngredientsForm: !this.state.showIngredientsForm
		});
	};
	editIngredients = () => {
		this.setState({
			editIngredientsForm: !this.state.editIngredientsForm,
			dishCalories: null,
			recipeNutrition: null
		});
	};
	handleClose = () => {
		this.setState({
			showIngredientsForm: false,
			editIngredientsForm: false
		});
	};
	addStep = () => {
		let newData = this.state.stepContent;
		this.setState(prevState => {
			const steps = [...prevState.steps];
			steps.push(newData);
			return { ...prevState, steps };
		});
		this.setState({
			stepContent: ''
		});
	};
	resetState = () => {
		this.setState({
			recipeTitle: 'Title Here',
			titleUrl: '',
			seoDesc: '',
			recipeDesc: '',
			cuisine: '',
			imageUrl: '',
			imageWebpUrl: '',
			category: '',
			dishType: '',
			cookTime: 0,
			prepTime: 0,
			servSize: 0,
			steps: [],
			views: 0,
			likes: 0,
			notes: '',
			Ingredients: [],
			showIngredientsForm: false,
			editIngredientsForm: false,
			stepContent: '',
			dishCalories: '',
			recipeNutrition: null
		});
	};
	calcRecipes = () => {
		let totalCal = 0;
		let CA = 0;
		let CHOCDF = 0;
		let CHOLE = 0;
		let FAMS = 0;
		let FAPU = 0;
		let FASAT = 0;
		let FAT = 0;
		let FATRN = 0;
		let FE = 0;
		let FIBTG = 0;
		let FOLDFE = 0;
		let K = 0;
		let MG = 0;
		let NA = 0;
		let ENERC_KCAL = 0;
		let NIA = 0;
		let P = 0;
		let PROCNT = 0;
		let RIBF = 0;
		let SUGAR = 0;
		let THIA = 0;
		let TOCPHA = 0;
		let VITA_RAE = 0;
		let VITB12 = 0;
		let VITB6A = 0;
		let VITC = 0;
		let VITD = 0;
		let VITK1 = 0;
		console.log('CA', CA);

		try {
			this.state.Ingredients.map(ing => {
				totalCal += (ing.caloriesPerWeight / ing.weight) * ing.quantity;
				if (ing.totalNutrients.CA.quantity > 0) {
					CA +=
						(ing.totalNutrients.CA.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.CHOCDF.quantity > 0) {
					CHOCDF +=
						(ing.totalNutrients.CHOCDF.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.CHOLE.quantity > 0) {
					CHOLE +=
						(ing.totalNutrients.CHOLE.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FAMS.quantity > 0) {
					FAMS +=
						(ing.totalNutrients.FAMS.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FAPU.quantity > 0) {
					FAPU +=
						(ing.totalNutrients.FAPU.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FASAT.quantity > 0) {
					FASAT +=
						(ing.totalNutrients.FASAT.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FAT.quantity > 0) {
					FAT +=
						(ing.totalNutrients.FAT.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FATRN.quantity > 0) {
					FATRN +=
						(ing.totalNutrients.FATRN.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FE.quantity > 0) {
					FE +=
						(ing.totalNutrients.FE.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FIBTG.quantity > 0) {
					FIBTG +=
						(ing.totalNutrients.FIBTG.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.FOLDFE.quantity > 0) {
					FOLDFE +=
						(ing.totalNutrients.FOLDFE.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.K.quantity > 0) {
					K +=
						(ing.totalNutrients.K.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.MG.quantity > 0) {
					MG +=
						(ing.totalNutrients.MG.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.NA.quantity > 0) {
					NA +=
						(ing.totalNutrients.NA.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.ENERC_KCAL.quantity > 0) {
					ENERC_KCAL +=
						(ing.totalNutrients.ENERC_KCAL.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.NIA.quantity > 0) {
					NIA +=
						(ing.totalNutrients.NIA.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.P.quantity > 0) {
					P +=
						(ing.totalNutrients.P.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.PROCNT.quantity > 0) {
					PROCNT +=
						(ing.totalNutrients.PROCNT.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.RIBF.quantity > 0) {
					RIBF +=
						(ing.totalNutrients.RIBF.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.SUGAR.quantity > 0) {
					SUGAR +=
						(ing.totalNutrients.SUGAR.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.THIA.quantity > 0) {
					THIA +=
						(ing.totalNutrients.THIA.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.TOCPHA.quantity > 0) {
					TOCPHA +=
						(ing.totalNutrients.TOCPHA.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.VITA_RAE.quantity > 0) {
					VITA_RAE +=
						(ing.totalNutrients.VITA_RAE.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.VITB12.quantity > 0) {
					VITB12 +=
						(ing.totalNutrients.VITB12.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.VITB6A.quantity > 0) {
					VITB6A +=
						(ing.totalNutrients.VITB6A.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.VITC.quantity > 0) {
					VITC +=
						(ing.totalNutrients.VITC.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.VITD.quantity > 0) {
					VITD +=
						(ing.totalNutrients.VITD.quantity / ing.weight) *
						ing.quantity;
				}
				if (ing.totalNutrients.VITK1.quantity > 0) {
					VITK1 +=
						(ing.totalNutrients.VITK1.quantity / ing.weight) *
						ing.quantity;
				}
			});
			console.log('CA', CA);
			let recipeNutrition = {
				CA: { CA: '	Calcium	', value: Number(CA), uom: '	mg	' },
				CHOCDF: { CHOCDF: '	Carbs	', value: Number(CHOCDF), uom: '	g	' },
				CHOLE: {
					CHOLE: '	Cholesterol	',
					value: Number(CHOLE),
					uom: '	mg	'
				},
				FAMS: {
					FAMS: '	Monounsaturated	',
					value: Number(FAMS),
					uom: '	g	'
				},
				FAPU: {
					FAPU: '	Polyunsaturated	',
					value: Number(FAPU),
					uom: '	g	'
				},
				FASAT: { FASAT: '	Saturated	', value: Number(FASAT), uom: '	g	' },
				FAT: { FAT: '	Fat	', value: Number(FAT), uom: '	g	' },
				FATRN: { FATRN: '	Trans	', value: Number(FATRN), uom: '	g	' },
				FE: { FE: '	Iron	', value: Number(FE), uom: '	mg	' },
				FIBTG: { FIBTG: '	Fiber	', value: Number(FIBTG), uom: '	g	' },
				FOLDFE: {
					FOLDFE: '	Folate (Equivalent)	',
					value: Number(FOLDFE),
					uom: '	μg	'
				},
				K: { K: '	Potassium	', value: Number(K), uom: '	mg	' },
				MG: { MG: '	Magnesium	', value: Number(MG), uom: '	mg	' },
				NA: { NA: '	Sodium	', value: Number(NA), uom: '	mg	' },
				ENERC_KCAL: {
					ENERC_KCAL: '	Energy	',
					value: Number(ENERC_KCAL),
					uom: '	kcal	'
				},
				NIA: { NIA: '	Niacin (B3)	', value: Number(NIA), uom: '	mg	' },
				P: { P: '	Phosphorus	', value: Number(P), uom: '	mg	' },
				PROCNT: { PROCNT: '	Protein	', value: Number(PROCNT), uom: '	g	' },
				RIBF: {
					RIBF: '	Riboflavin (B2)	',
					value: Number(RIBF),
					uom: '	mg	'
				},
				SUGAR: { SUGAR: '	Sugars	', value: Number(SUGAR), uom: '	g	' },
				THIA: { THIA: '	Thiamin (B1)	', value: Number(THIA), uom: '	mg	' },
				TOCPHA: {
					TOCPHA: '	Vitamin E	',
					value: Number(TOCPHA),
					uom: '	mg	'
				},
				VITA_RAE: {
					VITA_RAE: '	Vitamin A	',
					value: Number(VITA_RAE),
					uom: '	μg	'
				},
				VITB12: {
					VITB12: '	Vitamin B12	',
					value: Number(VITB12),
					uom: '	μg	'
				},
				VITB6A: {
					VITB6A: '	Vitamin B6	',
					value: Number(VITB6A),
					uom: '	mg	'
				},
				VITC: { VITC: '	Vitamin C	', value: Number(VITC), uom: '	mg	' },
				VITD: { VITD: '	Vitamin D	', value: Number(VITD), uom: '	μg	' },
				VITK1: { VITK1: '	Vitamin K	', value: Number(VITK1), uom: '	μg	' }
			};
			console.log('nurti CA', recipeNutrition.CA.value);
			this.setState({
				dishCalories: totalCal,
				recipeNutrition: recipeNutrition
			});
		} catch (err) {
			toast.error(
				'please add all the ingredients freshly to calculate the Calories'
			);
		}
	};
	handleChangeContent = (e, name) => {
		this.setState({
			[name]: e.currentTarget.textContent
		});
	};
	componentDidMount() {
		if (this.props.location.state) {
			let data = this.props.location.state;
			console.log(data);
			this.props.fetchIngredients();
			this.setData(data);
		} else {
			this.props.fetchIngredients();
		}
	}
	setData = data => {
		this.setState({
			recipeTitle: data.recipeTitle,
			author: data.author,
			titleUrl: data.titleUrl,
			seoDesc: data.seoDesc,
			recipeDesc: data.recipeDesc,
			cuisine: data.cuisine,
			imageUrl: data.imageUrl,
			imageWebpUrl: data.imageWebpUrl,
			category: data.category,
			dishType: data.dishType,
			cookTime: data.cookTime,
			prepTime: data.prepTime,
			servSize: data.servSize,
			steps: data.steps,
			views: data.views,
			likes: data.likes,
			notes: data.notes,
			Ingredients: data.Ingredients,
			dishCalories: data.dishCalories,
			recipeNutrition: data.recipeNutrition,
			id: data._id,
			bmrecipe: data.bmrecipe
		});
	};
	validate = () => {
		if (!this.state.seoDesc || !this.state.recipeDesc) {
			toast.error('description (SEO or VISIBLE) not added');
			return false;
		} else if (!this.state.dishCalories || !this.state.recipeNutrition) {
			toast.error('Calorie Information not calculated');
			return false;
		} else if (!this.state.imageUrl || !this.state.imageWebpUrl) {
			toast.error('Image not added properly');
			return false;
		} else if (
			this.state.steps.length === 0 ||
			this.state.Ingredients.length === 0
		) {
			toast.error(
				'Recipe preparation steps are empty or Ingredients are empty'
			);
			return false;
		} else if (
			this.state.cookTime === null ||
			this.state.prepTime === null
		) {
			toast.error('Timings are not entered');
			return false;
		} else if (
			!this.state.category ||
			!this.state.cuisine ||
			!this.state.dishType
		) {
			toast.info('Cuisine Type not added');
			return true;
		} else {
			return true;
		}
	};
	updateRecipe = () => {
		if (this.validate()) {
			console.log('req sent');
			console.log(this.state.prepTime);
			axios({
				method: 'post',
				url: `https://api.bestmom.org/article/updateRecipe`,
				data: {
					id: this.state.id,
					recipeTitle: this.state.recipeTitle,
					author: this.state.author,
					seoDesc: this.state.seoDesc,
					recipeDesc: this.state.recipeDesc,
					cuisine: this.state.cuisine,
					imageUrl: this.state.imageUrl,
					imageWebpUrl: this.state.imageWebpUrl,
					category: this.state.category,
					dishType: this.state.dishType,
					cookTime: Number(this.state.cookTime),
					prepTime: Number(this.state.prepTime),
					servSize: this.state.servSize,
					steps: this.state.steps,
					notes: this.state.notes,
					Ingredients: this.state.Ingredients,
					recipeNutrition: this.state.recipeNutrition,
					dishCalories: Number(this.state.dishCalories),
					bmrecipe: this.state.bmrecipe
				}
			})
				.then(response => {
					if (response.status === 200) {
						toast.success(
							`'updated successfully'${response.data}`,
							{ containerId: 'A' }
						);
						setTimeout(() => {
							this.resetState();
							this.props.history.push('/');
						}, 2000);
					}
				})
				.catch(function (error) { });
		}
	};
	render() {
		const cuisines = [
			'American',
			'British',
			'Caribbean',
			'Chinese',
			'French',
			'Greek',
			'Indian',
			'Italian',
			'Japanese',
			'Mediterranean',
			'Mexican',
			'Moroccon',
			'Spanish',
			'Thai',
			'Turkish',
			'Vietnamese'
		];
		const category = [
			'Appetizers',
			'Beverages',
			'Soups',
			'Salads',
			'Main Dish',
			'Desserts',
			'Breads',
			'Miscellaneous'
		];
		return (
			<RecipeWrap>
				<div className='recipe'>
					<Grid
						container
						direction='row'
						justify='flex-start'
						alignItems='center'>
						<Grid container item xs={12} md={6}>
							<h1
								contentEditable='true'
								onBlur={e =>
									this.handleChangeContent(e, 'recipeTitle')
								}
								className='recipeTitle'>
								{this.state.recipeTitle}
							</h1>
							<div className='recipeSummaryItems'>
								<div className='summaryItems'>
									{' '}
									<span className='recipeSummaryValue'>
										{this.state.Ingredients.length}
									</span>{' '}
									<span className='recipeSummaryItem'>
										Ingredients
									</span>
								</div>
								<div className='summaryItems'>
									<span className='recipeSummaryValue'>
										{this.state.prepTime +
											this.state.cookTime}
									</span>{' '}
									<span className='recipeSummaryItem'>{`Total Time (mins)`}</span>{' '}
								</div>
								<div className='summaryItems'>
									<span className='recipeSummaryValue'>
										{this.state.dishCalories}
									</span>{' '}
									<span className='recipeSummaryItem'>
										Calories
									</span>
								</div>
							</div>
						</Grid>
						<Grid
							container
							item
							xs={12}
							md={6}
							style={{ maxWidth: '400px' }}>
							{this.state.imageUrl ? (
								<img src={this.state.imageUrl} width={'100%'} />
							) : (
									<img src='https://via.placeholder.com/400' />
								)}
							<div className='buttons fadein'>
								<div className='button'>
									<input
										type='file'
										id='image'
										onChange={this.onChangeImage}
									/>
								</div>
							</div>
						</Grid>
					</Grid>
					<p className='recipe__desc'>{this.state.recipeDesc}</p>
					<div className='recipe__ingredients'>
						<h3>Ingredients</h3>
						<table className='table table-striped'>
							<tbody className='ingredients'>
								{this.state.Ingredients.length > 0 &&
									this.state.Ingredients.map((ing, i) => {
										return (
											<tr key={i}>
												<td>
													<img
														src={ing.imageUrl}
														width='25px'
														heigth='25px'></img>
												</td>
												<td>{ing.displayName}</td>
												<td>
													{ing.nonGramQty ||
														ing.quantity}
												</td>
												<td>{ing.nonGramUom || 'g'}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
					<div className='recipe__directions'>
						<h3>Directions</h3>
						<ol>
							{this.state.steps.map((step, i) => {
								return (
									<li key={i}>
										{step}
										<DeleteIcon
											onClick={() => this.deleteStep(i)}
										/>
									</li>
								);
							})}
						</ol>
					</div>
				</div>
				<div style={{ marginTop: '25px' }}>
					<span onClick={this.addIngredients}>
						<AddBoxIcon />
						ADD INGREDIENTS
					</span>
				</div>
				<div style={{ marginTop: '25px' }}>
					<span onClick={this.editIngredients}>
						<AddBoxIcon />
						EDIT INGREDIENTS QTY
					</span>
				</div>
				<hr style={{ marginBottom: '20px' }} />
				<TextField
					id='outlined-multiline-static'
					style={{ margin: '10px' }}
					multiline
					fullWidth
					placeholder='Enter Each Step Content and then Submit'
					name='stepContent'
					value={this.state.stepContent}
					variant='outlined'
					onChange={this.handleChange}
				/>
				<span onClick={this.addStep}>
					<AddBoxIcon />
					ADD STEP
				</span>
				<Modal
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					open={this.state.showIngredientsForm}
					onClose={this.handleClose}>
					<Paper
						style={{
							position: 'absolute',
							width: '90vw',
							top: '10vh',
							left: '5vw'
						}}>
						<MaterialTable
							title='Ingredients'
							actions={[
								{
									icon: 'add',
									tooltip: 'add ingredient',
									onClick: (event, rowData) =>
										this.setState({
											Ingredients: [
												...this.state.Ingredients,
												rowData
											]
										})
								}
							]}
							columns={[
								{
									title: 'Image',
									field: 'imageUrl',
									render: rowData => (
										<img
											style={{
												height: 36,
												borderRadius: '50%'
											}}
											src={rowData.imageUrl}
										/>
									)
								},
								{
									title: 'Ingredient Name',
									field: 'ingredientName'
								},
								{ title: 'Display Name', field: 'displayName' }
							]}
							data={this.props.recipes.ingredients}
						/>
					</Paper>
				</Modal>
				<Modal
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					open={this.state.editIngredientsForm}
					onClose={this.handleClose}>
					<Paper
						style={{
							position: 'absolute',
							width: '90vw',
							top: '10vh',
							left: '5vw'
						}}>
						<MaterialTable
							title='Ingredients'
							actions={[
								{
									icon: 'save',
									tooltip: 'save ingredient',
									onClick: (event, rowData) =>
										this.setState({
											Ingredients: [
												...this.state.Ingredients,
												rowData
											]
										})
								}
							]}
							columns={[
								{
									title: 'Image',
									field: 'imageUrl',
									render: rowData => (
										<img
											style={{
												height: 36,
												borderRadius: '50%'
											}}
											src={rowData.imageUrl}
										/>
									)
								},
								{
									title: 'Ingredient Name',
									field: 'ingredientName'
								},
								{ title: 'Display Name', field: 'displayName' },
								{
									title: 'qty in gm',
									field: 'quantity',
									type: 'numeric'
								},
								{
									title: 'qty non gm',
									field: 'nonGramQty',
									type: 'numeric'
								},
								{
									title: 'units non gm',
									field: 'nonGramUom',
									type: 'text'
								}
							]}
							data={this.state.Ingredients}
							editable={{
								onRowUpdate: (newData, oldData) =>
									new Promise(resolve => {
										setTimeout(() => {
											resolve();
											if (oldData) {
												this.setState(prevState => {
													const Ingredients = [
														...prevState.Ingredients
													];
													Ingredients[
														Ingredients.indexOf(
															oldData
														)
													] = newData;
													return {
														...prevState,
														Ingredients
													};
												});
											}
										}, 600);
									}),
								onRowDelete: oldData =>
									new Promise(resolve => {
										setTimeout(() => {
											resolve();
											this.setState(prevState => {
												const Ingredients = [
													...prevState.Ingredients
												];
												Ingredients.splice(
													Ingredients.indexOf(
														oldData
													),
													1
												);
												return {
													...prevState,
													Ingredients
												};
											});
										}, 600);
									})
							}}
						/>
					</Paper>
				</Modal>
				<TextField
					id='outlined-multiline-static'
					multiline
					fullWidth
					name='seoDesc'
					style={{ margin: '10px' }}
					placeholder='SEO Description'
					value={this.state.seoDesc}
					variant='outlined'
					onChange={this.handleChange}
				/>
				<TextField
					id='outlined-multiline-static'
					multiline
					style={{ margin: '10px' }}
					fullWidth
					name='recipeDesc'
					placeholder='Visible Description'
					value={this.state.recipeDesc}
					variant='outlined'
					onChange={this.handleChange}
				/>
				<div style={{ margin: '10px' }}>
					<FormControl variant='filled'>
						<Select
							id='outlined-multiline-static'
							name='cuisine'
							value={this.state.cuisine}
							variant='outlined'
							style={{ minWidth: 140, marginRight: '10px' }}
							onChange={this.handleChange}>
							<MenuItem value=''>
								<em>Cuisine Select</em>
							</MenuItem>
							{cuisines.map(cus => {
								return <MenuItem value={cus}>{cus}</MenuItem>;
							})}
						</Select>
						<FormHelperText>Cuisine</FormHelperText>
					</FormControl>
					<FormControl>
						<Select
							name='category'
							value={this.state.category}
							variant='outlined'
							style={{ minWidth: 140, marginRight: '10px' }}
							onChange={this.handleChange}>
							<MenuItem value=''>
								<em>Category Select</em>
							</MenuItem>
							{category.map(cat => {
								return <MenuItem value={cat}>{cat}</MenuItem>;
							})}
						</Select>
						<FormHelperText>Category</FormHelperText>
					</FormControl>
					<TextField
						id='outlined-multiline-static'
						name='dishType'
						value={this.state.dishType}
						variant='outlined'
						placeholder='Dish Type'
						onChange={this.handleChange}
						size='small'
					/>
				</div>
				<div style={{ margin: '10px' }}>
					<TextField
						id='outlined-multiline-static'
						name='cookTime'
						value={this.state.cookTime}
						type='number'
						variant='outlined'
						size='small'
						InputLabelProps={{
							shrink: true
						}}
						helperText='cook time'
						onChange={this.handleChange}
					/>
					<TextField
						id='outlined-multiline-static'
						name='prepTime'
						helperText='serve size'
						type='number'
						value={this.state.prepTime}
						onChange={this.handleChange}
						InputLabelProps={{
							shrink: true
						}}
						variant='outlined'
						// helperText='prep time'
					/>
					<TextField
						id='outlined-number'
						name='servSize'
						helperText='serve size'
						type='number'
						value={this.state.servSize}
						onChange={this.handleChange}
						InputLabelProps={{
							shrink: true
						}}
						variant='outlined'
					/>
				</div>
				<TextField
					id='outlined-multiline-static'
					multiline
					style={{ margin: '10px' }}
					fullWidth
					placeholder='additional notes'
					name='notes'
					value={this.state.notes}
					variant='outlined'
					onChange={this.handleChange}
				/>
				<FormGroup row>
					<FormControlLabel
						control={
							<Switch
								checked={this.state.bmrecipe}
								onChange={this.handleAuthor}
								color='secondary'
							/>
						}
						label={
							this.state.bmrecipe
								? 'AUTHOR : BESTMOM'
								: 'AUTHOR : FOODMEMO'
						}
					/>
				</FormGroup>
				<button
					className='btn btn-danger btn-lg btn-block'
					onClick={this.calcRecipes}>
					Calculate Calories
				</button>
				<button
					className='btn btn-danger btn-lg btn-block'
					onClick={this.updateRecipe}>
					UPDATE RECIPE
				</button>
			</RecipeWrap>
		);
	}
}
const mapStateToProps = state => ({
	recipes: state.recipes
});
export default connect(mapStateToProps, { fetchIngredients })(EditRecipe);

const RecipeWrap = styled.div`
	padding: 30px;

	.recipe {
		width: 100%;
		margin: 1rem auto;
		max-width: 1000px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 5px;
		color: #53383a;
		background-color: #fff;
		position: relative;
		overflow: hidden;
		border-radius: 15px;
	}

	.recipeTitle {
		color: #fff;
		font-weight: 900;
		text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
		font-family: Nunito;
		letter-spacing: 1px;
		width: 100%;
		text-align: center;
	}
	.recipeSummaryItems {
		width: 100%;
		display: flex;
		.summaryItems {
			width: 33%;
			text-align: center;
			.recipeSummaryValue {
				font-family: Nunito;
				font-size: 2rem;
				width: 100%;
				display: inline-block;
			}
			.recipeSummaryItem {
				display: block;
			}
		}
	}

	.recipe__desc {
		margin: 10px;
		padding-left: 1rem;
		padding-right: 1rem;
		font-family: Nunito;
	}

	.recipe__ingredients {
		width: 35%;
		margin-right: 2.5em;
		margin-left: 5px;
		margin-bottom: 2.5em;
		float: left;
		@media only screen and (max-width: 600px) {
			width: 100%;
		}
	}

	.recipe__ingredients h3 {
		margin-left: 3em;
		margin-top: 2em;
		font-family: Nunito;
	}

	.ingredients {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.ingredients li {
		line-height: 2;
		padding-left: 3em;
		margin: 0;
	}

	.recipe__directions {
		margin-top: 2.1em;
		padding-right: 2.5em;
		padding-bottom: 2.5em;
		padding-left: 1.5em;
	}

	.recipe__directions h3 {
		font-family: Average Sans, sans-serif;
	}

	h3 {
		margin-bottom: 2em;
		text-transform: uppercase;
		font-size: 1em;
	}

	ol li {
		text-align: justify;
		margin-bottom: 1em;
	}

	@media all and (max-width: 720px) {
		.recipe {
			margin: 0;
		}
		.recipe__title {
			background-position: 0 0;
			background-repeat: repeat-y;
			font-size: 1.5em;
			padding-left: 1em;
		}
		.recipe__desc {
			padding-left: 1.5em;
			padding-right: 1.5em;
		}
		.recipe__ingredients h3 {
			margin-left: 0;
			margin-top: 2em;
			padding-left: 1.5em;
		}
		.recipe__ingredients {
			float: none;
			display: block;
			width: 350px;
		}
		.recipe__directions {
			padding-left: 1.5em;
			padding-right: 1.5em;
		}
		.recipe__directions h3 {
			padding-left: 0em;
		}
		.recipe__ingredients li {
			margin-left: -1em;
		}
		.recipe__directions li {
			padding: 0;
			margin: 0;
			margin-left: -1em;
		}
	}

	.recipe__directions::after {
		margin-top: 2.5em;
		content: '☆ ☆ ☆';
		display: block;
		text-align: center;
	}
`;