import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
// Import React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom'
class PostTable extends React.Component {
	state = {
		sorted: [],
		page: 0,
		pageSize: 10,
		expanded: {},
		resized: [],
		filtered: [],
		data: [],
		header: 'ALL POSTS',
		modalContent: '',
		openModal: false,
	};
	handleDelete = (title, id) => {
		this.setState({
			modalContent: { title: title, id: id },
			openModal: true,
		});
	};
	deleteArticle = (id) => {
		if (id) {
			this.props.deleteArticle(id);
			this.setState({
				openModal: false,
				modalContent: '',
			});
		}
	};
	handleCloseModal = () => {
		this.setState({
			openModal: false,
			modalContent: '',
		});
	};

	componentDidMount() {
		let postsArray = this.props.postsArray;
		this.setState({
			data: postsArray,
			header: 'ALL POSTS',
		});
	}
	componentDidUpdate(prevProps) {
		if (prevProps.postsArray.length !== this.props.postsArray.length) {
			let postsArray = this.props.postsArray;
			this.setState({
				data: postsArray,
				header: 'ALL POSTS',
			});
		}
	}
	componentWillUnmount() {
		this.props.clearPostsArray();
	}

	resetState = () => {
		this.setState({
			sorted: [],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			data: [],
			header: 'ALL POSTS',
		});
	};
	makeData = (data, e) => {
		this.setState({
			data: data,
			header: `${e.target.innerHTML}`,
		});
	};

	render() {
		const { privy } = this.props
		const { data } = this.state;
		const { header } = this.state;
		let postsArray = this.props.postsArray;
		if (postsArray.length > 0) {
			let draftsArray = postsArray.filter((draft) => {
				return draft.draft === true;
			});
			let publishedArray = postsArray.filter((post) => {
				return post.published === true;
			});
			let waitingTBP = postsArray.filter((post) => {
				return post.waitingTBP === true;
			});
			let reviewed = postsArray.filter((post) => {
				return post.reviewed === true;
			});
			let readyToPub = postsArray.filter((post) => {
				return post.approved === true;
			});
			return (
				<div>
					<div>
						<button onClick={(e) => this.makeData(draftsArray, e)}>
							Drafts
						</button>
						<button onClick={(e) => this.makeData(waitingTBP, e)}>
							Waiting for Approval
						</button>
						<button
							onClick={(e) => this.makeData(publishedArray, e)}>
							Published
						</button>
						<button
							onClick={(e) => this.makeData(reviewed, e)}>
							Reviewed
						</button>
						<button
							onClick={(e) => this.makeData(readyToPub, e)}>
							Ready To Be Published
						</button>
						<button onClick={this.resetState}>Reset State</button>
					</div>
					<DeletePostsDialog
						openModal={this.state.openModal}
						closeModal={this.handleCloseModal}
						title={this.state.modalContent.title}
						id={this.state.modalContent.id}
						deleteArticle={this.deleteArticle}
						privy={privy}
					/>
            {   /*  RaactTable*/}
					<ReactTable
						data={data}
						noDataText='Please Click on the buttons to populate Data'
						columns={[
							{
								Header: `${header}`,
								columns: [
									{
										Header: 'TITLE',
										accessor: 'title',
										Cell: row => (
											<a href={`/articles/${row.original._id}`} target="_blank">{row.original.title}</a>
										),
										filterMethod: (filter, row) => {
											if (
												String(row[filter.id])
													.toLowerCase()
													.includes(
														filter.value.toLowerCase()
													)
											) {
												return true;
											}
										},
									},
									{
										Header: 'VIEWS',
										accessor: 'views',
									},
									{
										id: 'author_name',
										Header: 'AUTHOR',
										accessor: (d) => d.author.userName,
									},
									{
										id: 'DRAFT-STATUS',
										Header: 'DRAFT-STATUS',
										accessor: (d) => {
											if (d.draft) {
												return (d.draft && 'draft')
											} else if (d.published) {
												return (d.published && 'published')
											} else if (d.waitingTBP) {
												return (d.waitingTBP && 'waiting TBP')
											} else if (d.reviewed) {
												return (d.reviewed && 'reviewed')
											} else if (d.approved) {
												return (d.approved && 'approved')
											}
										},
									},
									{
										Header: 'DELETE',
										Cell: () => (
											<div
												style={{ textAlign: 'center' }}>
												<DeleteIcon />
											</div>
										),
									},
								],
							},
						]}
						filterable
						defaultPageSize={10}
						className='-striped -highlight'
						// Controlled props
						sorted={this.state.sorted}
						page={this.state.page}
						pageSize={this.state.pageSize}
						expanded={this.state.expanded}
						resized={this.state.resized}
						filtered={this.state.filtered}
						// Callbacks
						onSortedChange={(sorted) => this.setState({ sorted })}
						onPageChange={(page) => this.setState({ page })}
						onPageSizeChange={(pageSize, page) =>
							this.setState({ page, pageSize })
						}
						onExpandedChange={(expanded) =>
							this.setState({ expanded })
						}
						onResizedChange={(resized) =>
							this.setState({ resized })
						}
						onFilteredChange={(filtered) =>
							this.setState({ filtered })
						}
						getTdProps={(state, rowInfo, column, instance) => {
							return {
								onClick: (e, handleOriginal) => {
									// if (column.Header === 'TITLE') {
									// 	this.props.history.push({
									// 		pathname: `/article/${rowInfo.original._id}`,
									// 		state: rowInfo.original,
									// 	});
									// }
									if (column.Header === 'DELETE') {
										this.handleDelete(
											rowInfo.original.title,
											rowInfo.original._id
										);
										{
											/* this.props.deleteArticle(
											rowInfo.original._id
										); */
										}
									}
									if (column.Header === 'AUTHOR') {
										console.log(
											rowInfo.original.author._id
										);
									}
									console.log(
										'It was in this table instance:',
										instance
									);
									if (handleOriginal) {
										handleOriginal();
									}
								},
							};
						}}
					/>
				</div>
			);
		} else {
			return <button>Loading ..</button>;
		}
	}
}

export default withRouter(PostTable);

export function DeletePostsDialog({
	openModal,
	closeModal,
	title,
	id,
	deleteArticle,
	privy
}) {
	return (
		<div>
			<Dialog
				open={openModal}
				onClose={closeModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				{privy === 'admin' ? (
					<>
						<DialogTitle id='alert-dialog-title'>
							{'Do you really want to Delete the below article ?'}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>
								{title}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeModal} color='primary' autoFocus>
								No
					</Button>
							<Button onClick={() => deleteArticle(id)} color='primary'>
								Yes
					</Button>
						</DialogActions>
					</>
				) : (
						<DialogTitle id='alert-dialog-title'>
							{'Please contact Admin for deleting the post'}
						</DialogTitle>
					)}

			</Dialog>
		</div>
	);
}