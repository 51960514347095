/* reducers should accept action and state and return the new state after executing the action */

import {
	FETCH_INGREDIENTS,
	FETCH_RECIPES,
	CLEAR_RECIPES,
	DELETE_RECIPE
} from '../actions/types';

const initialState = {
	recipesArray: [],
	ingredients: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case FETCH_INGREDIENTS:
			return {
				...state,
				ingredients: action.payload
			};
		case FETCH_RECIPES:
			return {
				...state,
				recipesArray: action.payload
			};
		case DELETE_RECIPE:
			return {
				...state,
				recipesArray: action.payload
			};
		case CLEAR_RECIPES:
			return {
				...state,
				recipesArray: []
			};
		default:
			return state;
	}
}