import React from 'react';
import { withRouter } from 'react-router-dom';
// Import React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import DeleteIcon from '@mui/icons-material/Delete';

class MembersTable extends React.Component {
	state = {
		sorted: [],
		page: 0,
		pageSize: 10,
		expanded: {},
		resized: [],
		filtered: []
	};
	componentDidMount() {
		console.log(this.props);
	}
	componentWillUnmount() {
		// this.props.clearPostsArray()
	}

	resetState = () => {
		this.setState({
			sorted: [],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: []
		});
	};

	render() {
		let members = this.props.members;
		// let draftsArray = postsArray.filter((draft) => {
		//     return draft.draft === true
		// })
		// let publishedArray = postsArray.filter((post) => {
		//     return post.published === true
		// })
		// let waitingTBP = postsArray.filter((post) => {
		//     return post.waitingTBP === true
		// })
		return (
			<div>
        {/* ReactTable*/}
				<ReactTable
					data={members}
					noDataText='Please Click on the buttons to populate Data'
					columns={[
						{
							Header: `MEMBERS`,
							columns: [
								{
									Header: 'NAME',
									accessor: 'memberName',
									filterMethod: (filter, row) => {
										if (
											String(row[filter.id])
												.toLowerCase()
												.includes(
													filter.value.toLowerCase()
												)
										) {
											return true;
										}
									}
								},
								{
									Header: 'EMAIL',
									accessor: 'memberEmail'
								},
								{
									Header: 'PHOTO URL',
									accessor: 'photo.jpeg'
								},
								{
									Header: 'DEPARTMENT',
									accessor: 'department'
								},
								{
									Header: 'DELETE',
									Cell: () => (
										<div style={{ textAlign: 'center' }}>
											<DeleteIcon />
										</div>
									)
								}
							]
						}
					]}
					filterable
					defaultPageSize={10}
					className='-striped -highlight'
					// Controlled props
					sorted={this.state.sorted}
					page={this.state.page}
					pageSize={this.state.pageSize}
					expanded={this.state.expanded}
					resized={this.state.resized}
					filtered={this.state.filtered}
					// Callbacks
					onSortedChange={sorted => this.setState({ sorted })}
					onPageChange={page => this.setState({ page })}
					onPageSizeChange={(pageSize, page) =>
						this.setState({ page, pageSize })
					}
					onExpandedChange={expanded => this.setState({ expanded })}
					onResizedChange={resized => this.setState({ resized })}
					onFilteredChange={filtered => this.setState({ filtered })}
					getTdProps={(state, rowInfo, column, instance) => {
						return {
							onClick: (e, handleOriginal) => {
								if (column.Header === 'DELETE') {
									this.props.deleteMember(
										rowInfo.original.memberEmail
									);
								}
								console.log(
									'It was in this table instance:',
									instance
								);
								if (handleOriginal) {
									handleOriginal();
								}
							}
						};
					}}
				/>
			</div>
		);
	}
}

export default withRouter(MembersTable);

// import React from 'react';
// import { useHistory } from 'react'; // Import useHistory hook
// import DeleteIcon from '@mui/icons-material/Delete';

// class MembersTable extends React.Component {
//   state = {
//     sorted: [],
//     page: 0,
//     pageSize: 10,
//     expanded: {},
//     resized: [],
//     filtered: []
//   };

//   // ...

//   handleDelete = (memberEmail) => {
//     // Handle the delete action here
//     this.props.deleteMember(memberEmail);

//     // Use the history object to navigate to a different route
//     const history = useHistory();
//     history.push('/your-target-route'); // Replace with your desired target route
//   };

//   render() {
//     // ...
//     let members = this.props.members;
//     return (
//       <div>
//         {/* ReactTable */}
//         <div
//           data={members}
//           noDataText="Please Click on the buttons to populate Data"
//           columns={[
//             {
//               Header: `MEMBERS`,
//               columns: [
//                 {
//                   Header: 'NAME',
//                   accessor: 'memberName',
//                   filterMethod: (filter, row) => {
//                     if (
//                       String(row[filter.id])
//                         .toLowerCase()
//                         .includes(filter.value.toLowerCase())
//                     ) {
//                       return true;
//                     }
//                   }
//                 },
//                 {
//                   Header: 'EMAIL',
//                   accessor: 'memberEmail'
//                 },
//                 {
//                   Header: 'PHOTO URL',
//                   accessor: 'photo.jpeg'
//                 },
//                 {
//                   Header: 'DEPARTMENT',
//                   accessor: 'department'
//                 },
//                 {
//                   Header: 'DELETE',
//                   Cell: ({ row }) => (
//                     <div style={{ textAlign: 'center' }}>
//                       <DeleteIcon
//                         onClick={() => this.handleDelete(row.original.memberEmail)} // Call handleDelete with the email
//                       />
//                     </div>
//                   )
//                 }
//               ]
//             }
//           ]}
//           filterable
//           defaultPageSize={10}
//           className="-striped -highlight"
//           // Controlled props
//           sorted={this.state.sorted}
//           page={this.state.page}
//           pageSize={this.state.pageSize}
//           expanded={this.state.expanded}
//           resized={this.state.resized}
//           filtered={this.state.filtered}
//           // Callbacks
//           onSortedChange={sorted => this.setState({ sorted })}
//           onPageChange={page => this.setState({ page })}
//           onPageSizeChange={(pageSize, page) =>
//             this.setState({ page, pageSize })
//           }
//           onExpandedChange={expanded => this.setState({ expanded })}
//           onResizedChange={resized => this.setState({ resized })}
//           onFilteredChange={filtered => this.setState({ filtered })}
//         />
//       </div>
//     );
//   }
// }

// export default MembersTable;


